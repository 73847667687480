
export const Status = ({exchanges}) => {


  return (
    <div>
        <span className={'toggle active'}>ACTIVE CONNECTIONS: </span>
      {
        exchanges.sort((a, b) => a.source.localeCompare(b.source))
        .map((exchange) => (
          <span
            style={{cursor: 'pointer'}}
            key={exchange.id}
            className={exchange.connection_status ? 'connected' : 'not_connected'}>
            {exchange.source} &nbsp;
          </span>
        ))
      }
    </div>
  )
}

