
export const Toggle = ({elements, setElements}) => {

  const toggleElement = (id) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === id ? { ...element, active: !element.active } : element
      )
    );
  };

  const toggleAll = () => {
    setElements((elements) =>
    elements.map(element =>
      { 
        element.active = true
        return element 
      }
      )
    )
  }

  const toggleNone = () => {
    setElements((elements) =>
    elements.map(element =>
      { 
        element.active = false
        return element 
      }
      )
    )
  }

  return (
    <div>
      <span style={{cursor: 'pointer'}} onClick={() => toggleAll()} className={'toggle active'}>- All -&nbsp;</span>
      <span className={'toggle'}>&nbsp;&nbsp;</span>
      <span style={{cursor: 'pointer'}} onClick={() => toggleNone()} className={'toggle'}>- None -</span>
      <span className={'toggle'}>&nbsp;&nbsp;&nbsp;</span>
      {
        elements.sort((a, b) => a.text.localeCompare(b.text)).map((element) => (
          <span
            style={{cursor: 'pointer'}}
            key={element.id}
            onClick={() => toggleElement(element.id)}
            className={element.active ? 'toggle active' : 'toggle'}>
            {element.text} &nbsp;
          </span>
        ))
      }
    </div>
  )
}

export const is_active = (text,elements) => {
    let element = elements.find(x => x.text===text)
    if (element!=undefined) return element.active
    else return true
}

export const purge_local_storage = (initial_data,local_storage_key) => {
  let local_storage_data = JSON.parse(window.localStorage.getItem(local_storage_key))
  if (local_storage_data==undefined || local_storage_data==0) return

  let non_active_local_storage_items = local_storage_data.filter(x => { return x.active===false }).map(a => a.text)
  if (non_active_local_storage_items==undefined || non_active_local_storage_items.length==0) return
  let new_local_storage_data = []
  initial_data.forEach(d => {
      if (non_active_local_storage_items.includes(d.text)) d.active = false
      new_local_storage_data.push(d)
  })
  window.localStorage.setItem(local_storage_key, JSON.stringify(new_local_storage_data))
}

