import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import {Table} from './Table'
import { FundingContext } from '../context/FundingDataProvider'
import EventEmitter from '../tools/Events'
import { MdBrowseGallery } from 'react-icons/md'
import {exchanges,UTC_ALL,UTC_0_8_16,UTC_4_12_20, ConfigContext} from '../context/ConfigProvider'

export default function SuperOpportunitiesTable({title, types, investment, filteredSymbols, filteredExchanges, funding_exceptions})
{ 
    const { fundingData } = useContext(FundingContext)

    const long_color = '#88CC88'
    const short_color = '#EE8888'
    const symbol_color = '#CCCCCC'
    const missing_color = '#666666'
    const investment_color = '#AAAAAA'
    const gmt = -3

    const columns = [
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'Symbol',
            accessor: 'symbol',
            Cell: ({ value }) => {
              return <div style={{ color: symbol_color }}>&nbsp;{value}</div>
            }
          },          
          {
            Header: 'Last',
            accessor: 'last',
            Cell: ({ row,value }) => {
              return <div style={{ color: long_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'F 4h',
            accessor: 'f4',
            Cell: ({ value }) => {
                return tools.format_positive_funding(value)
            }
          },
          {
            Header: 'F 8h',
            accessor: 'f8',
            Cell: ({ value }) => {
                return tools.format_positive_funding(value)
            }
          },
          {
            Header: 'F 12h',
            accessor: 'f12',
            Cell: ({ row,value }) => {
              if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
              else 
              return tools.format_positive_funding(value)
            }
          },
          {
            Header: 'F 16h',
            accessor: 'f16',
            Cell: ({ row,value }) => {
              if (row.id%2){
                if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
                else 
                return tools.format_positive_funding(value)
              }
              if (row.id%3){
                if (value=='NaN') return ''
                else return tools.format_currency(value) 
              }

            }
          }
    ]
    const date = new Date()
    let current_hour = date.getUTCHours()
    let next_funding_hours = tools.get_next_four_funding_hours(current_hour)

    let table_data = tools.data_super_opportunities(fundingData,types,funding_exceptions,date,next_funding_hours)
    let table_with_investment = tools.fill_investment_data(table_data,investment)
    
    let sanitized_table = tools.sanitize(table_with_investment)
    let fees = ''
    if (table_data.length>0)
    fees = table_data[0].fees
    let sorted_data = sanitized_table.filter((e) => { return e.funding_arbitrage>0})
    .sort(
      (a, b) => {
        return b.funding_arbitrage - a.funding_arbitrage }
    )
    
    /*

    let sorted_data_2 = sorted_data.sort(
      (row_a, row_b) => {
      if (
         row_a.pnl>row_b.pnl &&
         row_a.long_missing_predicted==false && 
         row_a.short_missing_predicted==false && 
         row_b.long_missing_predicted==false && 
         row_b.short_missing_predicted==false
         )
         {
          return 1
         }
         else 
         {
          return 0
         }
      }
    )
      */

    let filtered_data = sorted_data.filter((e) => {
      return (parseFloat(e.entry_cost)+parseFloat(e.funding_pnl))>0
    })
    /*)
    filtered_data = filtered_data.filter((e) => { 
      return e.long_missing_predicted==false
    })
    filtered_data = filtered_data.filter((e) => { 
      return e.short_missing_predicted==false
    })
    */
    filtered_data = filtered_data.filter((e) => { 
      return e.funding_pnl>0
    })
    filtered_data = filtered_data.filter((e) => { 
      return e.predicted_pnl>0
    })

    let filtered_symbols_data = []
    filteredSymbols.forEach(symbol => {
        if (symbol.active)
        {
          filtered_data.forEach(row => {
            if (row['symbol']==symbol.text) filtered_symbols_data.push(row)
        })
        }
    })

    let filtered_exchanges_data = []
        filtered_symbols_data.forEach(row => {
              let add = true
              filteredExchanges.forEach(exchange => {
                if ((row['long_exchange']==exchange.text) || (row['short_exchange']==exchange.text)) 
                {
                     if (!exchange.active) {
                      add=false
                      return
                    }
                }
              })
              if (add) filtered_exchanges_data.push(row)
        })


    let sorted_by_symbols = tools.sort_by_symbol(filtered_exchanges_data)

    let mobile_data = tools.mobile_rows(sorted_by_symbols)


    return (
        <>
            <center><span className='spread text'>Mobile {title ? title : symbol} - Exchange Fee:  {fees ? '$ '+fees : ''}</span><span className='spread text'> - (UTC {date.getUTCHours()+":"+date.getUTCMinutes()})</span></center>
            <Table columns={columns} data={mobile_data}/>
        </>
    )

}



//FundingTable.whyDidYouRender = true;