import { useContext } from 'react'
import { useParams, Link } from 'react-router-dom';
import { ConfigContext, USDT } from '../context/ConfigProvider'
import { RealtimeContext } from '../context/RealtimeDataProvider'
import * as tools from '../tools/tools'

const Strategy = () => {

  const {Styles, funding_exceptions} = useContext(ConfigContext)
  const { realtimeData } = useContext(RealtimeContext)

  const GMT = -3
  const date = new Date()
  let current_hour = date.getUTCHours()
  let next_funding_hours = tools.get_next_four_funding_hours(current_hour)
  let funding_hours = ['h','h','h','h']
  if (next_funding_hours!=undefined) 
    if (next_funding_hours.next_hours!=undefined) 
    {
      funding_hours = [tools.adjustGMT(next_funding_hours.next_hours[0],GMT)+'h',
      tools.adjustGMT(next_funding_hours.next_hours[1],GMT)+'h',
      tools.adjustGMT(next_funding_hours.next_hours[2],GMT)+'h',
      tools.adjustGMT(next_funding_hours.next_hours[3],GMT)+'h']
    }

  let table_data = tools.data_super_opportunities(realtimeData,[USDT],funding_exceptions,date,next_funding_hours)
  let { symbol,long_exchange,short_exchange } = useParams()

  let filtered_data = table_data.filter((row) => {
    return (row.symbol==symbol && row.long_exchange==long_exchange && row.short_exchange==short_exchange)
  })

  let strategy_data
  if (filtered_data.length>0)
  {
    strategy_data = filtered_data[0]
  }


  return (
    <Styles>
      <br></br>
      <Link to="/super_opportunities">Back to List</Link>
      <br></br>
        <span>{strategy_data ? strategy_data.symbol : ''}</span>
        <br></br>
        <span>{strategy_data ? strategy_data.long_exchange : ''}</span>&nbsp;
        <span>{strategy_data ? strategy_data.long_last : ''}</span>
        <br></br>
        <span>{strategy_data ? strategy_data.short_exchange : ''}</span>&nbsp;
        <span>{strategy_data ? strategy_data.short_last : ''}</span>
    </Styles>
  )
}

export default Strategy

