import axios from 'axios'
import * as tools from '../tools/tools'
import { useRef, useEffect, useCallback } from 'react'
import EventEmitter from '../tools/Events'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'

export const SOURCE = 'FTX'

export function Ftx(symbols,update_internal,update_connection){

    const ws_url = 'wss://ftx.com/ws'
    const webSocket = useRef(null)
    let parse = tools.dynamic_decimals
    let parse100 = tools.dynamic_decimals_by_100
    let json = JSON.stringify

    const ftx_funding = useCallback((symbols,update_internal) => {
        symbols.forEach (symbol => {
            fetch_ftx(symbols, symbol.symbol,update_internal)
        })
        async function fetch_ftx(symbols, symbol,update_internal){
                const res = await axios('https://fundingarb.com.ar/ftx_futures', 
                { params: { symbol: symbol } })
        
                if ( res?.data?.result?.nextFundingRate)
                {
                    update_internal(symbols,symbol,{
                        funding_rate: parse100(parseFloat(res.data.result.nextFundingRate)*8),
                    })
                }
        }
    }, [parse100])


    useEffect(() => {

        ftx_funding(symbols,update_internal)
        const interval = setInterval(() => {
            ftx_funding(symbols,update_internal)
        }, 20000)

        webSocket.current = new WebSocket(ws_url)
        const ws = webSocket.current

        // {'op': 'subscribe', 'channel': 'markets'}
        ws.onopen = () => {
            update_internal(symbols,undefined,{status: <MdCheckCircle />})
            update_connection(SOURCE,true)
            ws.send(json({op: 'subscribe', channel: 'markets'}))
            symbols.forEach (symbol => {
                ws.send(json({op: 'subscribe', channel: 'trades', market: `${symbol.symbol}`}))
            })
        }

        const listener = EventEmitter.addListener('webSocketClick',(values) => {
           let symbol = tools.getSymbol(values,symbols)
           if (symbol !== undefined)
            {
                ConsoleHelper(symbol)
            }
        })

        ws.onclose = () => {
            update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
            update_connection(SOURCE,false)
        }

        
        ws.onmessage = (message) => {
            try {
                let json_message = JSON.parse(message.data)

                if (json_message?.data!==undefined) 
                {
                    if (json_message.channel==='markets')
                    {
                        for (let [key, ftx_symbol] of Object.entries(json_message.data.data)) {
                            let symbol = symbols.find(symbol => symbol.symbol === key)
                            if (symbol !== undefined)
                            {
                                if (ftx_symbol?.future?.expiry!==null)
                                update_internal(symbols,symbol.symbol,{
                                    expiry: Date.parse(ftx_symbol.future.expiry)
                                })
                            }

                        }
                    }
                    else
                    if (json_message.channel==='trades')
                    {
                        update_internal(symbols,json_message.market,{
                            last: parse(json_message.data[0].price) 
                        })
                    }
                    else
                    {
                        ConsoleHelper(SOURCE,json_message)
                    }

                }
            } catch(e) {
                ConsoleHelper(e)
            }
        }

        return () => {
            //webSocket.current.close()
            //clearInterval(interval)
            //listener.remove()
        }
    }, [symbols,update_internal,parse,ftx_funding,json])

    return webSocket.current
}


