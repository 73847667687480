import { TextField } from '@mui/material';
import { useState } from 'react'

export const RangeField = ({title, range, setRange, step}) => {

  const [selected, setSelected] = useState(false)

  const onBlurField = () => {
    setSelected(false)
  }

  const changeMin = (value) => {
    setRange([value,range[1]])
  }

  const changeMax = (value) => {
    setRange([range[0],value])
  }

  const rangeText = () => {
    return (
      <>
        <TextField
            id="standard-number"
            label="Min"
            type="number"
            inputProps={{
              step: step
            }}
            onChange={(event) => changeMin(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={() => onBlurField()}
            variant="standard"
            defaultValue={range[0]}
        />
        <TextField
          id="standard-number"
          label="Max"
          type="number"
          inputProps={{
            step: step
          }}
          onChange={(event) => changeMax(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => onBlurField()}
          variant="standard"
          defaultValue={range[1]}
        />
      </>

    )
  }

  const text = () => {
    return (
      <>
        <span
        className={'toggle'}
        onClick={() => setSelected(true)}>{title}:&nbsp;
        </span>
        <span
        className={'toggle active'}
        onClick={() => setSelected(true)}>{parseFloat(range[0]).toFixed(2)}/{parseFloat(range[1]).toFixed(2)}
        </span>
      </>
    )
  }

  return (
    <div>
      { selected ? rangeText() : text() }
    </div>
  )

}

