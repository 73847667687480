import axios from 'axios'
import * as tools from '../tools/tools'
import { useRef, useEffect, useCallback } from 'react'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'
import rateLimit from 'axios-rate-limit';

export const SOURCE = 'KUCOIN'

let kucoin_listed_symbols = []

// https://docs.kucoin.com/futures/#get-real-time-symbol-ticker-v2

export function Kucoin(symbols, update_internal, update_connection, data) {

    const z_socket = useRef(null)
    const connectId = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100

    const kucoin_funding = useCallback((symbols, update_internal) => {

        kucoin_listed_symbols.forEach(symbol => {
            fetch_kucoin_funding(symbols, symbol, update_internal)
        })

        async function fetch_kucoin_funding(symbols, symbol, update_internal) {
            //const http = rateLimit(axios.create(), { maxRequests: 2, perMilliseconds: 1000, maxRPS: 2 })
            const res = await axios('https://fundingarb.com.ar/kucoin_futures', { params: { symbol: symbol } })

            if (res?.data) {
                if (res.data.code === '200000') {
                    update_internal(symbols, symbol, {
                        funding_rate: parse100(res.data.data.value),
                        predicted_funding_rate: parse100(res.data.data.predictedValue)
                    })
                }
            }
        }
    }, [parse100])


    useEffect(() => {

        symbols.forEach(symbol => { kucoin_listed_symbols.push(symbol.symbol) })

        const connect = async () => {
            try {
                const res = await axios('https://www.fundingarb.com.ar/kucoin_token')

                let endpoint_url = res.data.data.instanceServers[0].endpoint
                let pingInterval = res.data.data.instanceServers[0].pingInterval
                let token = res.data.data.token
                connectId.current = Date.now()

                let ws = new ZSocket(`${endpoint_url}?token=${token}&[connectId=${connectId.current}]`, NO_COMPRESSION); z_socket.current = ws

                //webSocket.current = new WebSocket(`${endpoint_url}?token=${token}&[connectId=${connectId.current}]`)

                z_socket.current.onopen = () => {
                    update_internal(symbols, undefined, { status: <MdCheckCircle /> })
                    update_connection(SOURCE, true)
                    symbols.forEach(symbol => {

                        ws.sendJson({
                            id: `${connectId.current}`,
                            type: 'subscribe',
                            topic: `/contractMarket/execution:${symbol.symbol}`,
                            response: true
                        })
                    })
                    ping_interval.current = setInterval(() => {
                        let ping_id = Date.now()
                        let ping_message = { id: `${ping_id}`, type: 'ping' }
                        z_socket.current.sendJson(ping_message)
                    }, pingInterval)
                }

                z_socket.current.onclose = () => {
                    update_internal(symbols, undefined, { status: <MdHourglassEmpty /> })
                    update_connection(SOURCE, false)
                    setTimeout(() => connect(), 2000)
                }

                z_socket.current.jsonReceived = (json_message) => {
                    if (json_message.subject === 'match') {
                        let instrument = json_message.topic.replace('/contractMarket/execution:', '')
                        update_internal(symbols, instrument, {
                            last: parse(json_message.data.price)
                        })
                    }
                    else if (json_message?.type === 'welcome') { /* Connection established */ }
                    else if (json_message?.type === 'ack') { /* Subscription Acknowledged */ }
                    else if (json_message?.type === 'pong') { /* Ping */ }
                    else if (json_message?.type === 'error' && json_message?.code === 404) { /* Instrument is not found */ }
                    else {
                        ConsoleHelper(SOURCE, json_message)
                    }
                }

            } catch (e) {
                //ConsoleHelper(e)
            }
        }


        connect()

        kucoin_funding(symbols, update_internal)
        const interval = setInterval(() => {
            kucoin_listed_symbols = tools.purge_listed_symbols(data, SOURCE, kucoin_listed_symbols)
            kucoin_funding(symbols, update_internal)
        }, 60000)

        return () => {
        }
    }, [symbols, update_internal, parse, kucoin_funding])

    return z_socket.current
}

