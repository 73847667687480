import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import {Table} from './Table'
import { FuturesContext } from '../context/FuturesDataProvider'
import EventEmitter from '../tools/Events'

export default function FuturesTable({title, symbol, less_than_days, greater_than_days, types})
{
    const columns = React.useMemo(
      () => [
        {
          Header: 'ID',
          accessor: 'id', // accessor is the 'key' in the data
        },
        {
          Header: 'Source',
          accessor: 'source',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color) {
              return (
              <div style={{ background: row.original.highlight_color,  color: '#FFF'}} 
              onClick={() => {EventEmitter.emit('highlightRow',row.values)}}
              >{value}</div>
              )
            }
            return (
              <div onClick={() => {EventEmitter.emit('highlightRow',row.values)}}
              >{value}</div>
            )
          }

        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color,  color: '#FFF' }} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: '',
          accessor: 'type_icon',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color ,  color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Perpetual',
          accessor: 'related_last',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Index',
          accessor: 'index',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Future',
          accessor: 'future_last',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },{
          Header: 'Spread',
          accessor: 'spread',
          sortType: 'basic',
          headerClassName: 'right',
          className: 'right',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Exp',
          accessor: 'expiration',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Days',
          accessor: 'days',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        
        {
          Header: 'Rate',
          accessor: 'expiration_rate',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Daily',
          accessor: 'daily_rate',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Annual',
          accessor: 'annual_rate',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'WS',
          accessor: 'status',
          className: 'fixed-width',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color ,  color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        }
      ],
      []
    )
    
    const { futuresData } = useContext(FuturesContext)
    let table_data
    if (less_than_days) table_data = tools.symbol_data_realtime(futuresData,symbol,types).filter(symbol => symbol.days < less_than_days && symbol.days > 0).sort((a,b) => a.days - b.days)
    else if (greater_than_days) table_data = tools.symbol_data_realtime(futuresData,symbol,types).filter(symbol => symbol.days > greater_than_days && symbol.days > 0).sort((a,b) => a.days - b.days)
    else table_data = tools.symbol_data_realtime(futuresData,symbol,types).sort((a,b) => a.days - b.days)
    
    return (
      <>
        { title && <center><span className='spread text'>{title ? title : symbol}</span></center> } 
        { table_data.length > 0 && 
          <Table titulo={title ? title : symbol} columns={columns} data={table_data} />
        }
      </>
    )

}

//RealtimeTable.whyDidYouRender = true;