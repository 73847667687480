import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import {Table} from './Table'
import { FundingContext } from '../context/FundingDataProvider'
import EventEmitter from '../tools/Events'
import { MdBrowseGallery } from 'react-icons/md'
import {exchanges,UTC_ALL,UTC_0_8_16,UTC_4_12_20} from '../context/ConfigProvider'

export default function StrategyTable({title, types, investment, filteredSymbols, filteredExchanges})
{ 
    const { fundingData } = useContext(FundingContext)
    const long_color = '#88CC88'
    const short_color = '#EE8888'
    const symbol_color = '#CCCCCC'
    const missing_color = '#666666'
    const investment_color = '#AAAAAA'

    const columns = [
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'Symbol',
            accessor: 'symbol',
            Cell: ({ value }) => {
              return <div style={{ color: symbol_color }}>&nbsp;{value}</div>
            }
          },          
          {
            Header: 'E',
            accessor: 'exchanges',
            Cell: ({ value }) => {
              return <div style={{ color: symbol_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'Long',
            accessor: 'long_exchange',
            Cell: ({ value }) => {
              return <div style={{ color: long_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'H',
            accessor: 'long_exchange_hours',
            Cell: ({ row,value }) => {
              let exchange = exchanges.filter(exchange => exchange.text == row.original.long_exchange)
              return <div style={{ color: exchange[0].funding_hours }}><MdBrowseGallery /></div>
            }
          },
          {
            Header: 'Last',
            accessor: 'long_last',
            Cell: ({ row,value }) => {
              return <div style={{ color: long_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'Funding',
            accessor: 'long_funding_rate',
            Cell: ({ row,value }) => {
              return <div style={{ color: long_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'Predicted',
            accessor: 'long_predicted_funding_rate',
            Cell: ({ row,value }) => {
              if (row.original.long_missing_predicted) return <div style={{ color: missing_color }}>&nbsp;{value}</div>
              else return <div style={{ color: long_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'Short',
            accessor: 'short_exchange',
            Cell: ({ row,value }) => {
              return <div style={{ color: short_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'H',
            accessor: 'short_exchange_hours',
            Cell: ({ row,value }) => {
              let exchange = exchanges.filter(exchange => exchange.text == row.original.short_exchange)
              return <div style={{ color: exchange[0].funding_hours }}><MdBrowseGallery /></div>
            }
          },
          {
            Header: 'Last',
            accessor: 'short_last',
            Cell: ({ row,value }) => {
              return <div style={{ color: short_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'Funding',
            accessor: 'short_funding_rate',
            Cell: ({ row,value }) => {
              return <div style={{ color: short_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'Predicted',
            accessor: 'short_predicted_funding_rate',
            Cell: ({ row,value }) => {
              if (row.original.short_missing_predicted) return <div style={{ color: missing_color }}>&nbsp;{value}</div>
              else return <div style={{ color: short_color }}>&nbsp;{value}</div>
            }
          },
          ,
          {
            Header: 'Funding Arb',
            accessor: 'funding_arbitrage',
            Cell: ({ value }) => {
                return tools.format_positive_funding(value)
            }
          },
          {
            Header: 'Predicted Arb',
            accessor: 'predicted_arbitrage',
            Cell: ({ row,value }) => {
                if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
                else 
                return tools.format_positive_funding(value)
            }
          },
          {
            Header: 'Quantity',
            accessor: 'quantity',
            Cell: ({ value }) => {
              return <div style={{ color: investment_color }}>&nbsp;{value}</div>
            }
          },
          {
            Header: 'Entry Arb',
            accessor: 'entry_cost',
            Cell: ({ value }) => {
              if (value=='NaN') return ''
              else return tools.format_currency(value) 
            }
          },
          {
            Header: 'Funding PNL',
            accessor: 'funding_pnl',
            Cell: ({ value }) => {
              if (value=='NaN') return ''
              else return tools.format_currency(value) 
            }
          },
          {
            Header: 'Predicted PNL',
            accessor: 'predicted_pnl',
            Cell: ({ row,value }) => {
              if (value=='NaN') return ''
              if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_currency(value,'',true) 
              else 
              return tools.format_currency(value) 
            }
          },
          {
            Header: 'PNL',
            accessor: 'pnl',
            Cell: ({ row,value }) => {
              if (value=='NaN') return ''
              if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_currency(value,'',true) 
              else 
              return tools.format_currency(value) 
            }
          }
    ]
    

    let table_data = tools.data_strategy(fundingData,types)
    let table_with_investment = tools.fill_investment_data(table_data,investment)
    let sanitized_table = tools.sanitize(table_with_investment)
    let fees = ''
    if (table_data.length>0)
    fees = table_data[0].fees
    let sorted_data = sanitized_table.filter((e) => { return e.funding_arbitrage>0})
    .sort(
      (a, b) => { 
        return b.funding_arbitrage - a.funding_arbitrage }
    )

    let filtered_symbols_data = []
    filteredSymbols.forEach(symbol => {
        if (symbol.active)
        {
          sorted_data.forEach(row => {
            if (row['symbol']==symbol.text) filtered_symbols_data.push(row)
        })
        }
    })

    let filtered_exchanges_data = []
        filtered_symbols_data.forEach(row => {
              let add = true
              filteredExchanges.forEach(exchange => {
                if ((row['long_exchange']==exchange.text) || (row['short_exchange']==exchange.text)) 
                {
                     if (!exchange.active) {
                      add=false
                      return
                    }
                }
              })
              if (add) filtered_exchanges_data.push(row)
        })
    

    let sorted_by_symbols = tools.sort_by_symbol(filtered_exchanges_data)

    return (
        <>
            <center><span className='spread text'>{title ? title : symbol} - Approx Exchange Fee:  {fees ? '$ '+fees : ''}</span></center>
            <Table columns={columns} data={sorted_by_symbols}/>
        </>
    )

}



//FundingTable.whyDidYouRender = true;