import pako from 'pako'
import ConsoleHelper from '../tools/ConsoleHelper'

export const NO_COMPRESSION = 0
export const INFLATE = 1
export const INFLATE_RAW = 2
const wait_for_connection_interval = 1000

const tryJSON = (message) => {
    try {
        return {isJSON: true, message: JSON.parse(message)}
    } catch (e) {
        return {isJSON: false, message: message}
    }
}

export class ZSocket extends WebSocket {

    constructor(url,type,protocols) {
        super(url, protocols)
        this.type = type
        this.onmessage = (message) => {
            switch (this.type)
            {
                case INFLATE:
                case INFLATE_RAW:
                    inflateMessage(message,type,(message)=>{
                        let json = tryJSON(message)
                        if (json.isJSON) if (this.jsonReceived) this.jsonReceived(json.message)
                        else if (this.messageReceived) this.messageReceived(message)
                    })
                break
                case NO_COMPRESSION:
                    {
                    let json = tryJSON (message.data)
                    if (json.isJSON) if (this.jsonReceived) this.jsonReceived(json.message)
                    else if (this.messageReceived) this.messageReceived(message.data)
                    }
                break
                default:
            }
        }
    }

    sendJson = (json) => {
        this.wait_for_readyState(() => {
            this.send(JSON.stringify(json))
        }, wait_for_connection_interval)
    }

    sendMessage = (message) => {
        this.wait_for_readyState(() => {
            this.send(message)
        }, wait_for_connection_interval)
    }

    wait_for_readyState = (callback, interval) => {
        if (this.readyState === 1) {
            callback();
        } else {
            let that = this
            // optional: implement backoff for interval here
            setTimeout(function () {
                that.wait_for_readyState(callback, interval)
            }, interval)
        }
    }


}

const inflateMessage = (message, type, callback) => {
    message.data.arrayBuffer().then(inputArray => 
        {
            let compressed = new ArrayBuffer(inputArray.byteLength)
            new Uint8Array(compressed).set(new Uint8Array(inputArray))
            try {
                let decompressed
                switch (type){
                    case INFLATE:
                        decompressed = pako.inflate(compressed, {'to':'string'})
                    break
                    case INFLATE_RAW:
                        decompressed = pako.inflateRaw(compressed, {'to':'string'})
                    break
                    default:
                }
                callback(decompressed)
            }
            catch (err) {
                ConsoleHelper(err)
            }
        }
    )
}
