import React, { useContext } from 'react'
import 'fontsource-roboto'
import './App.css'
import { ConfigContext, COIN,USDT,BUSD } from './context/ConfigProvider'
import FuturesTable from './tables/FuturesTable'

function Futures() {

  const  { config, Styles } = useContext(ConfigContext)

  return (
    <Styles>
      <br></br><br></br><br></br><br></br>
      {
        config.symbols.map((symbol) => (
          <React.Fragment key={symbol.symbol}>
            <br></br>
            <FuturesTable symbol={symbol.symbol} types={[COIN,USDT,BUSD]} />
            <br></br>
          </React.Fragment>
        ))
      }
    </Styles>
  )
  
}

export default Futures
