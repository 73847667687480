import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import { RealtimeContext } from '../context/RealtimeDataProvider'

export default function Euro({title})
{ 
    const { realtimeData } = useContext(RealtimeContext)

    let euro_price = tools.euro_price(realtimeData,'BITMEX','XBTUSD','XBTEUR')

    return (
        <>
            <center><span className='spread text'>{title ? title : 'BITMEX'}: ${euro_price.toFixed(4)}</span></center>
        </>
    )

}



//FundingTable.whyDidYouRender = true;
