import React, {useContext, useState} from 'react'
import { ConfigContext } from './ConfigProvider'

export const FundingContext = React.createContext()

export const FundingDataProvider = ({ children }) => {

    const {initialData} = useContext(ConfigContext)
    const [fundingData, setFundingData] = useState(Array.from(initialData))


    return (
        <FundingContext.Provider value={{
            fundingData, setFundingData
        }}>
            { children }
        </FundingContext.Provider>
    )
}
