import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { PERPETUAL,FUTURES } from '../context/ConfigProvider'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
//import ConsoleHelper from '../tools/ConsoleHelper'
export const SOURCE = 'OKX'

/*
API Information
https://www.okx.com/docs-v5/en/#websocket-api-public-channel
*/

export function Okx(ws_url,symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {

        const connect = () => {
            let ws = new ZSocket(ws_url, NO_COMPRESSION); webSocket.current = ws
            
            let update = (symbol,row) => {
                update_internal(symbols,symbol,row)
            }

            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    switch (symbol.contract)
                    {
                        case PERPETUAL:
                            ws.sendJson({ op: 'subscribe', args: [{channel: 'funding-rate', instId: symbol.symbol}]})
                            ws.sendJson({ op: 'subscribe', args: [{channel: 'tickers', instId: symbol.symbol}]})
                        break
                        case FUTURES:
                            ws.sendJson({ op: 'subscribe', args: [{channel: 'mark-price', instId: symbol.symbol}]})
                        break
                        default:
                    }
                    ws.sendJson({ op: 'subscribe', args: [{channel: 'index-tickers', instId: `${symbol.source_symbol.symbol}-USD`}]})
                    ws.sendJson({ op: 'subscribe', args: [{channel: 'index-tickers', instId: `${symbol.source_symbol.symbol}-USDT`}]})
                })
                ws.sendJson({ op: 'subscribe', args: [{channel: 'instruments', instType: 'FUTURES'}]})
    
                ping_interval.current = setInterval(() => {
                    ws.send('ping')
                }, 20000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (jsonMessage) => {
                if (jsonMessage.arg?.channel==='funding-rate')
                {
                    if (jsonMessage?.data!==undefined)
                    {
                        let data = jsonMessage.data[0]
                        update_internal(symbols,data.instId,{
                            funding_rate: parse100(data.fundingRate),
                            predicted_funding_rate: parse100(data.nextFundingRate)
                        })
                    }
                }
                else if (jsonMessage.arg?.channel==='tickers')
                {
                    if (jsonMessage?.data!==undefined)
                    {
                        let data = jsonMessage.data[0]
                        update_internal(symbols,data.instId,{
                            last: parse(data.last)
                        })
                    }
                }
                else if (jsonMessage.arg?.channel==='mark-price')
                {
                    if (jsonMessage?.data!==undefined)
                    {
                        let data = jsonMessage.data[0]
                        update_internal(symbols,data.instId,{
                            last: parse(data.markPx)
                        })
                    }
                }
                else if (jsonMessage.arg?.channel==='index-tickers')
                {
                    if (jsonMessage?.data!==undefined)
                    {
                        let data = jsonMessage.data[0]
                        symbols.filter(symbol => symbol.contract === FUTURES).forEach (symbol => {
                            if (data.instId.startsWith(symbol.source_symbol.symbol))
                            {
                                update(symbol.symbol,{index: parse(data.idxPx)})
                            }
                        })
                    }
                }
                else if (jsonMessage.arg?.channel==='instruments')
                {
                    if (jsonMessage.arg?.instType === 'FUTURES')
                    {
                        if (jsonMessage?.data!==undefined)
                        {
                            let data = jsonMessage.data
                            symbols.filter(symbol => symbol.contract === FUTURES).forEach (symbol => {
                                let instrument = data.find(future => future.instId === symbol.symbol)
                                if (instrument) 
                                    {          
                                        console.log(instrument)   
                                        let expiry = new Date(parseInt(instrument.expTime))
                                        update_internal(symbols,instrument.instId,{
                                        expiry: expiry
                                        })
                                    }
                            })
                        }
                    }

                }
                else if (jsonMessage.event==='subscribe') {
                    // Do Nothing
                }
                else{
                    //ConsoleHelper({SOURCE,jsonMessage})
                }
            }
        }
        connect()

        return () => {
            //ws.close()
        }

    }, [ws_url,symbols,update_internal,parse,parse100])

    return webSocket.current
}

