import * as tools from '../tools/tools'
import { useRef, useEffect, useCallback } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { FUTURES, USDT, BUSD } from '../context/ConfigProvider'
import axios from 'axios'
import ConsoleHelper from '../tools/ConsoleHelper'
 
export const SOURCE = 'BINANCE'

export function Binance(ws_url,rest_url,symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    const update = useCallback((symbol,values) => 
        { update_internal(symbols,symbol,values) }, [symbols,update_internal] )
    const json = JSON.stringify

    useEffect(() => {
        binance_get_expiry({url: rest_url, symbols: symbols, update_internal })
        binance_get_expiry({url: rest_url, symbols: symbols, update_internal })

        const connect = () => {
            webSocket.current = new WebSocket(ws_url)
            const ws = webSocket.current
            webSocket.current.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                let mark_symbols = []
                let index_symbols = []
                symbols.forEach (symbol => {
                    let markPrice = `${symbol.symbol.toLowerCase()}@markPrice`
                    let indexPrice = `${symbol.source_symbol.symbol.toLowerCase()}usd@indexPrice`
                    if (symbol.type === USDT) indexPrice = `${symbol.source_symbol.symbol.toLowerCase()}usdt@indexPrice`
                    if (symbol.type === BUSD) indexPrice = `${symbol.source_symbol.symbol.toLowerCase()}busd@indexPrice`
                    mark_symbols.push(markPrice)
                    index_symbols.push(indexPrice)
                })
            ws.send(json({method: 'SUBSCRIBE',params:mark_symbols, id: 1}))
            ws.send(json({method: 'SUBSCRIBE',params:index_symbols, id: 2}))
            }
            
            webSocket.current.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            webSocket.current.onmessage = (message) => {
                try {
                    let json_message = JSON.parse(message.data)
                    if (json_message?.s!==undefined) {
                        update_internal(symbols,json_message.s,{
                            last: parse(json_message.p),
                            funding_rate: parse100(json_message.r)
                        })
                    } 
                    else if (json_message?.e!==undefined && json_message?.e === 'indexPriceUpdate')
                    {
                        if (json_message?.i!==undefined) {
                            symbols.filter(symbol => symbol.contract === FUTURES).forEach (symbol => {
                                if (json_message.i.startsWith(symbol.source_symbol.symbol))
                                {
                                    update(symbol.symbol,{index: parse(json_message.p)})
                                }
                            })
                        }
                    }
                    else if (json_message?.result===null) { /* OK */ }
                    else
                    {
                        ConsoleHelper(SOURCE,json_message)
                    }
                } catch(e) {
                    ConsoleHelper(e)
                }
            }
        }
        connect()

        return () => {
        }

    }, [ws_url,rest_url,symbols,update_internal,parse,parse100,update,json])

    return webSocket.current
}


const binance_get_expiry = ({url,symbols,update_internal}) => {
    axios.get(url)
    .then(res => {
      const data = res.data
      symbols.forEach (symbol => {
        let dapi_symbol = data.symbols.find(dapi => dapi.symbol === symbol.symbol.toUpperCase())
        if (symbol.contract === FUTURES) {
            if (dapi_symbol?.deliveryDate!==undefined)
            update_internal (symbols, symbol.symbol,{expiry: new Date(dapi_symbol.deliveryDate)})
        }
      })
    })
}
