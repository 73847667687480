import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import ConsoleHelper from '../tools/ConsoleHelper'

export const SOURCE = 'GATE.IO'

/*
API Information
https://www.gate.io/docs/futures/#server-url
*/

export function GateIO(ws_url,symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {
        const connect = () => {
            let ws = new ZSocket(ws_url, NO_COMPRESSION); webSocket.current = ws

            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)

                symbols.forEach (symbol => {
                    ws.sendJson(
                        {
                        'time' : 123456, 
                        'channel' : 'futures.tickers', 
                        'event': 'subscribe', 
                        'payload' : [symbol.symbol]
                        }          
                    )
                })

                ping_interval.current = setInterval(() => {
                    ws.sendJson({'time' : 123456, 'channel' : 'futures.ping'})
                }, 20000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (jsonMessage) => {
                if (jsonMessage?.result?.[0])
                {
                    let symbol_data = jsonMessage.result[0]
                    symbols.forEach (symbol => {
                        if (symbol_data.contract===symbol.symbol)
                        {
                            update_internal(symbols,symbol_data.contract,{
                                last: parse(symbol_data.last),
                                funding_rate: parse100(symbol_data.funding_rate),
                                predicted_funding_rate: parse100(symbol_data.funding_rate_indicative)
                            })
                        }
                    })
                }
                else if (jsonMessage?.event === 'subscribe') { /* Subscription made */ }
                else if (jsonMessage?.channel === 'futures.pong') { /* Ping */ }
                else
                {
                    ConsoleHelper(SOURCE,jsonMessage)
                }
            }
        }
        connect()

        return () => {
            //ws.close()
        }

    }, [ws_url,symbols,update_internal,parse,parse100])

    return webSocket.current
}

