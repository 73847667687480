
export const Switch = ({options, setOptions}) => {

  const toggleElement = (id) => {
    setOptions((options) =>
    options.map(option =>
      { 
        if (option.id===id) option.active = true
        else option.active = false
        return option 
      }
    )
    )
  }

  return (
    <div>
      {
        options.map((option) => (
          <span
            style={{cursor: 'pointer'}}
            key={option.id}
            onClick={() => toggleElement(option.id)}
            className={option.active ? 'toggle active' : 'toggle'}>
            {option.text} &nbsp;
          </span>
        ))
      }
    </div>
  )
}

