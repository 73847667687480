import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'
import { ZSocket, NO_COMPRESSION } from './ZSocket'

export const SOURCE = 'BYBIT'

export function Bybit(ws_url,symbols,update_internal,update_connection){

    const z_socket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals_by_100
    const parse_std = tools.dynamic_decimals

    const check_value_e4 = (value_in) => {
        let value = parseFloat(value_in/100/100)
        let value_out = parseFloat(value_in/100/100/100)
        if (value > 1)
        {
            return value
        }
        else {
            return value_out
        }
    }

    useEffect(() => {

        const connect = () => {
            let ws = new ZSocket(ws_url, NO_COMPRESSION); z_socket.current = ws

            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    ws.send(JSON.stringify({ op: 'subscribe', args:[`tickers.${symbol.symbol}`]}))
                })
                ping_interval.current = setInterval(() => {
                    let ping_message = {op:'ping'}
                    ws.send(JSON.stringify(ping_message))
                }, 30000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (json_message) => { 
                if (json_message !== null)
                {
                    if (Object.prototype.hasOwnProperty.call(json_message,'type'))
                    {
                        if (json_message.type==='snapshot')
                        {
                            let data = json_message.data
                            let expiry
                            if (data.settle_time_e9!==undefined) {
                                let settle_time_e9 = data.settle_time_e9/1000000
                                expiry = new Date(settle_time_e9)
                            }

                            update_internal(symbols,json_message.data.symbol,{
                                last: parse_std(data.lastPrice),
                                funding_rate: parse(data.fundingRate), 
                                expiry: expiry
                            })

                            if (data.indexPrice !== undefined)
                            {
                                update_internal(symbols,data.symbol,{
                                    index: parse_std(data.indexPrice)
                                })
                            }
                            if (data.markPrice !== undefined)
                            {
                                update_internal(symbols,data.symbol,{
                                    mark: parse_std(data.markPrice)
                                })
                            }
                        }
                        else
                        if (json_message.type==='delta')
                        {
                            let data = json_message.data
                            if (data.fundingRate !== undefined)
                            {
                                update_internal(symbols,data.symbol,{
                                    funding_rate: parse(data.fundingRate)
                                })
                            }
                            if (data.lastPrice !== undefined)
                            {
                                update_internal(symbols,data.symbol,{
                                    last: parse_std(data.lastPrice)
                                })
                            }
                            if (data.indexPrice !== undefined)
                            {
                                update_internal(symbols,data.symbol,{
                                    index: parse_std(data.indexPrice)
                                })
                            }
                            if (data.markPrice !== undefined)
                            {
                                update_internal(symbols,data.symbol,{
                                    mark: parse_std(data.markPrice)
                                })
                            }
                        }
                        else
                        {
                            ConsoleHelper(SOURCE,json_message)
                        }
                    }
                }
            }
        }
        connect()


        return () => {
        }

    }, [ws_url,symbols,update_internal,parse])
}
