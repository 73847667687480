import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import { Table } from './Table'
import { RealtimeContext } from '../context/RealtimeDataProvider'

export default function PositionsTable ({ title, positions })
{
    const columns = React.useMemo(
      () => [
        {
          Header: 'ID',
          accessor: 'id', // accessor is the "key" in the data
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
        },
        {
          Header: 'Quantity',
          accessor: 'quantity',
        },
        {
          Header: 'Long',
          accessor: 'long_exchange'
        },
        {
          Header: 'Entry',
          accessor: 'long_entry'
        },
        {
          Header: 'Last',
          accessor: 'long_last'
        },
        {
          Header: 'Short',
          accessor: 'short_exchange'
        },
        {
          Header: 'Entry',
          accessor: 'short_entry'
        }
        ,
        {
          Header: 'Last',
          accessor: 'short_last'
        },
        {
          Header: 'Entry PNL',
          accessor: 'entry_pnl',
          Cell: ({ row,value }) => {
            if (value=='NaN') return ''
            return tools.format_currency(value) 
          }
        },
        {
          Header: 'Exit PNL',
          accessor: 'exit_pnl',
          Cell: ({ row,value }) => {
            if (value=='NaN') return ''
            return tools.format_currency(value) 
          }
        },
        {
          Header: 'PNL',
          accessor: 'pnl',
          Cell: ({ row,value }) => {
            if (value=='NaN') return ''
            return tools.format_currency(value) 
          }
        }
      ],
      []
    )

    const { realtimeData } = useContext(RealtimeContext)

    let table_data = tools.symbol_data_positions(realtimeData,positions)

    return (
      <>
        <center><span className="spread text">{title}</span></center>
        <Table titulo={title} columns={columns} data={table_data} />
      </>
    )

}

//RealtimeTable.whyDidYouRender = true;