import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'
 
export const SOURCE = 'MEXC'


export function Mexc(symbols,update_internal,update_connection){

    const z_socket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {
        const connect = () => {
            let ws = new ZSocket('wss://futures.mexc.com/edge', NO_COMPRESSION); z_socket.current = ws
 
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
    
                symbols.forEach (symbol => {
                    ws.sendJson({"method":"sub.tick.batch","param":{"timezone":"UTC-3","symbols":[symbol.symbol]}})
                })

                ping_interval.current = setInterval(() => {
                    ws.sendJson({"method":"ping"})
                }, 20000)
            }
    
            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }
    
            ws.jsonReceived = (jsonMessage) => {
                if (jsonMessage?.channel=='push.ticker')
                {
                    let data = jsonMessage.data
                    if (data.fundingRate !== undefined)
                    {
                        update_internal(symbols,data.symbol,{
                            funding_rate: parse100(data.fundingRate)
                        })
                    }
                    if (data.lastPrice !== undefined)
                    {
                        update_internal(symbols,data.symbol,{
                            last: parse(data.lastPrice)
                        })
                    }
                }
                else {
                    ConsoleHelper(SOURCE,jsonMessage)
                }

            }
        }
        connect()

        return () => {
        }

    }, [symbols,update_internal,parse,parse100])

    return z_socket.current
}

