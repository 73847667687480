import React, {useContext, useState} from 'react'
import { ConfigContext } from './ConfigProvider'

export const FuturesContext = React.createContext()

export const FuturesDataProvider = ({ children }) => {

    const {initialData} = useContext(ConfigContext)
    const [futuresData, setFuturesData] = useState(Array.from(initialData))

    return (
        <FuturesContext.Provider value={{
            futuresData, setFuturesData
        }}>
            { children }
        </FuturesContext.Provider>
    )
}

//DataProvider.whyDidYouRender = true;

