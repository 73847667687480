import React, { useState } from 'react'

export const UPSContext = React.createContext()

export const UPSDataProvider = ({ children }) => {

    const [updateRate, setUpdateRate] = useState([])

    return (
        <UPSContext.Provider value={{updateRate, setUpdateRate}}>
            { children }
        </UPSContext.Provider>
    )
}

//DataProvider.whyDidYouRender = true;

