import { useContext, useEffect, useMemo, useCallback, useRef } from 'react'
import * as tools from '../tools/tools'

import * as BITMEX from './bitmex'
import * as BINANCE from './binance'
import * as BYBIT from './bybit'
import * as OKX from './okx'
import * as HTX from './htx'
//import * as FTX from './ftx'
import * as DERIBIT from './deribit'
import * as KUCOIN from './kucoin'
import * as POLONIEX from './poloniex'
import * as PHEMEX from './phemex'
import * as ASCENDEX from './ascendex'
//import * as DELTA from './delta'
import * as GATE_IO from './gate_io'
//import * as BITGET from './bitget'
import * as COINEX from './coinex'
import * as KRAKEN from './kraken'
import * as BINGX from './bingx'
import * as MEXC from './mexc'
import * as DYDX from './dydx'
import * as HYPE from './hyperliquid'

import { RealtimeContext } from '../context/RealtimeDataProvider'
import { FundingContext } from '../context/FundingDataProvider'
import { FuturesContext } from '../context/FuturesDataProvider'
import { UPSContext } from '../context/UPSDataProvider'
import { ConfigContext, COIN,USDT,EUR,BUSD,PERPETUAL,FUTURES, mutex } from '../context/ConfigProvider'

import EventEmitter from '../tools/Events'

let data = []
let update_second = 0
let update_seconds = [
    { id: 1, source: 'BITMEX', ups:0, connection_status: false },
    { id: 2, source: 'BINANCE', ups:0, connection_status: false },
    { id: 3, source: 'BYBIT', ups:0, connection_status: false },
    { id: 4, source: 'OKX', ups:0, connection_status: false },
    { id: 5, source: 'HTX', ups:0, connection_status: false },
    { id: 6, source: 'DERIBIT', ups:0, connection_status: false },
    { id: 7, source: 'KUCOIN', ups:0, connection_status: false },
    { id: 8, source: 'POLONIEX', ups:0, connection_status: false },
    { id: 9, source: 'PHEMEX', ups:0, connection_status: false },
    //{ id: 10, source: 'DELTA', ups:0, connection_status: false },
    //{ id: 11, source: 'GATE.IO', ups:0, connection_status: false },
    { id: 12, source: 'COINEX', ups:0, connection_status: false },
    { id: 13, source: 'KRAKEN', ups:0, connection_status: false },
    { id: 14, source: 'BINGX', ups:0, connection_status: false },
    { id: 15, source: 'MEXC', ups:0, connection_status: false },
    { id: 16, source: 'DYDX', ups:0, connection_status: false },
    { id: 17, source: 'ASCENDEX', ups:0, connection_status: false },
    { id: 18, source: 'HYPE', ups:0, connection_status: false },
    ]

const test = false
const realtime_refresh = 333
const funding_refresh = 5000
const futures_refresh = 3000
const ups_refresh = 1000

function SocketManager(){
    const {symbol_list, initialData } = useContext(ConfigContext)

    const {setRealtimeData} = useContext(RealtimeContext)
    const {setFundingData} = useContext(FundingContext)
    const {setFuturesData} = useContext(FuturesContext)
    const {setUpdateRate} = useContext(UPSContext)

    const update_connection = useCallback((source_exchange,connection_status) => {
        for (const exchange of update_seconds) {
            if (exchange.source == source_exchange) {
                exchange.connection_status = connection_status
                break
            }
        }
    }, // eslint-disable-next-line
    [])

    const update_internal = useCallback(
        async(symbols,symbol_to_update,values) => {
            await mutex.runExclusive(() => {
                let current_second = new Date().getSeconds()
                if (symbols.length>0)
                {
                    let source = symbols[0].source.SOURCE
                    if (update_second!==current_second) {
                        update_second = current_second
                        for (const exchange of update_seconds) {      
                                exchange.ups = 0
                        }

                    } else {
                        for (const exchange of update_seconds) {
                            if (exchange.source === source) {
                                exchange.ups++
                                break
                            }
                        }
                    }

                    symbols.forEach (symbol => {
                        if (symbol_to_update!==undefined)
                        {
                            if (symbol.symbol.toUpperCase() === symbol_to_update.toUpperCase())
                            {
                                updateRow(symbol,values)
                            }
                        }
                        else {
                            updateRow(symbol,values)
                        }
                    })
                }
            })
      }, // eslint-disable-next-line
      []) 

    //const ftx_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,FTX,[COIN,USDT]), [symbol_list])
    const okx_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,OKX,[COIN,USDT]), [symbol_list])
    const binance_coin_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BINANCE,[COIN]), [symbol_list])
    const binance_usdt_busd_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BINANCE,[USDT,BUSD]), [symbol_list])
    
    const bitmex_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BITMEX,[COIN,USDT,EUR]), [symbol_list])
    //const bybit_coin_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BYBIT,[COIN]), [symbol_list])
    const bybit_usdt_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BYBIT,[USDT]), [symbol_list])
    const deribit_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,DERIBIT,[COIN,USDT]), [symbol_list])
    const kucoin_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,KUCOIN,[COIN,USDT]), [symbol_list])
    const poloniex_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,POLONIEX,[COIN,USDT]), [symbol_list])
    const phemex_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,PHEMEX,[COIN,USDT]), [symbol_list])
    const ascendex_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,ASCENDEX,[COIN,USDT]), [symbol_list])
    //const delta_exchange_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,DELTA,[COIN,USDT]), [symbol_list])
    const coinex_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,COINEX,[COIN,USDT]), [symbol_list])

    const gate_io_coin_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,GATE_IO,[COIN]), [symbol_list])
    const gate_io_usdt_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,GATE_IO,[USDT]), [symbol_list])

    //const bitget_coin_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BITGET,[COIN]), [symbol_list])
    //const bitget_usdt_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BITGET,[USDT]), [symbol_list])

    const htx_coin_perpetual = useMemo(() => tools.find_symbol_from_source(symbol_list,HTX,[COIN],[PERPETUAL]), [symbol_list])
    const htx_usdt_perpetual = useMemo(() => tools.find_symbol_from_source(symbol_list,HTX,[USDT],[PERPETUAL]), [symbol_list])
    const htx_coin_futures = useMemo(() => tools.find_symbol_from_source(symbol_list,HTX,[COIN],[FUTURES]), [symbol_list])
    
    const kraken_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,KRAKEN,[COIN,USDT]), [symbol_list])

    const bingx_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,BINGX,[COIN,USDT]), [symbol_list])

    const mexc_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,MEXC,[USDT]), [symbol_list])

    const dydx_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,DYDX,[COIN]), [symbol_list])

    const hype_symbols = useMemo(() => tools.find_symbol_from_source(symbol_list,HYPE,[COIN]), [symbol_list])
    //if (!test || test===FTX) FTX.Ftx(ftx_symbols,update_internal)

    if (!test || test===DERIBIT) DERIBIT.Deribit(deribit_symbols,update_internal,update_connection)

    if (!test || test===HTX) HTX.Htx('wss://api.hbdm.com/swap-notification',htx_coin_perpetual,HTX.ORDER_PUSH,update_internal,update_connection)
    if (!test || test===HTX) HTX.Htx('wss://api.hbdm.com/linear-swap-notification',htx_usdt_perpetual,HTX.ORDER_PUSH,update_internal,update_connection)
    if (!test || test===HTX) HTX.Htx('wss://api.hbdm.com/linear-swap-ws',htx_usdt_perpetual,HTX.MARKET_DATA_REQUEST,update_internal,update_connection)
    if (!test || test===HTX) HTX.Htx('wss://api.hbdm.com/swap-ws',htx_coin_perpetual,HTX.MARKET_DATA_REQUEST,update_internal,update_connection)
    if (!test || test===HTX) HTX.Htx('wss://api.hbdm.com/ws',htx_coin_futures,HTX.MARKET_DATA_REQUEST,update_internal,update_connection)

    if (!test || test===BINANCE) BINANCE.Binance('wss://dstream.binance.com/ws','https://dapi.binance.com/dapi/v1/exchangeInfo',binance_coin_symbols,update_internal,update_connection)
    if (!test || test===BINANCE) BINANCE.Binance('wss://fstream.binance.com/ws','https://fapi.binance.com/fapi/v1/exchangeInfo',binance_usdt_busd_symbols,update_internal,update_connection)
 
    if (!test || test===BITMEX) BITMEX.Bitmex(bitmex_symbols,update_internal,update_connection)

    //if (!test || test===BYBIT) BYBIT.Bybit('wss://stream.bytick.com/realtime_public',bybit_usdt_symbols,update_internal,update_connection)
    if (!test || test===BYBIT) BYBIT.Bybit('wss://stream.bybit.com/v5/public/linear',bybit_usdt_symbols,update_internal,update_connection)
    
    //if (!test || test===BYBIT) BYBIT.Bybit('wss://stream.bybit.com/realtime',bybit_coin_symbols,update_internal,update_connection)

    if (!test || test===KUCOIN) KUCOIN.Kucoin(kucoin_symbols,update_internal,update_connection,data)

    if (!test || test===OKX) OKX.Okx('wss://ws.okx.com:8443/ws/v5/public',okx_symbols,update_internal,update_connection)

    if (!test || test===POLONIEX) POLONIEX.Poloniex(poloniex_symbols,update_internal,update_connection,data)

    if (!test || test===PHEMEX) PHEMEX.Phemex(phemex_symbols,update_internal,update_connection)

    if (!test || test===ASCENDEX) ASCENDEX.Ascendex(ascendex_symbols,update_internal,update_connection)

    if (!test || test===COINEX) COINEX.Coinex(coinex_symbols,update_internal,update_connection)

    if (!test || test===KRAKEN) KRAKEN.Kraken(kraken_symbols,update_internal,update_connection)

    if (!test || test===MEXC) MEXC.Mexc(mexc_symbols,update_internal,update_connection)

    if (!test || test===DYDX) DYDX.dYdX(dydx_symbols,update_internal,update_connection)

    if (!test || test===HYPE) HYPE.Hype(hype_symbols,update_internal,update_connection)

    //if (!test || test===DELTA) DELTA.DeltaExchange(delta_exchange_symbols,update_internal,update_connection)

    if (!test || test===BINGX) BINGX.BingX(bingx_symbols,update_internal,update_connection,data)
    //if (!test || test===BINGX) BINGX.BingX('wss://open-ws-swap.bingbon.pro/ws',bingx_symbols,update_internal)
    

    //if (!test || test===GATE_IO) GATE_IO.GateIO('wss://fx-ws.gateio.ws/v4/ws/btc',gate_io_coin_symbols,update_internal,update_connection)
    //if (!test || test===GATE_IO) GATE_IO.GateIO('wss://fx-ws.gateio.ws/v4/ws/usdt',gate_io_usdt_symbols,update_internal,update_connection)

    //if (!test || test===BITGET) BITGET.Bitget('wss://csocketapi.bitget.com/ws/v1',bitget_coin_symbols,update_internal)
    //if (!test || test===BITGET) BITGET.Bitget('wss://ws.bitget.com/mix/v1/stream',bitget_usdt_symbols,update_internal)


    const interval_realtime = useRef(null)
    const interval_funding = useRef(null)
    const interval_futures = useRef(null)
    const interval_ups = useRef(null)

    const setRealtime = useRef(null)
    const setFunding = useRef(null)
    const setFutures = useRef(null)
    const setUPS = useRef(null)


    setUPS.current = useCallback(() => {
        setUpdateRate(Array.from(update_seconds))
    },[setUpdateRate])

    setRealtime.current = useCallback(() => {
        setRealtimeData(data.filter(symbol => symbol.contract === PERPETUAL).sort((a,b) => b.last - a.last))
    },[setRealtimeData])

    setFunding.current = useCallback(() => {
        setFundingData(data.filter(symbol => symbol.contract === PERPETUAL).sort((a,b) => b.funding_rate - a.funding_rate))
    },[setFundingData])

    setFutures.current = useCallback(() => {
        let futures_data = tools.get_futures_data(data)
        setFuturesData(futures_data.sort((a,b) => b.last - a.last))
    },[setFuturesData])


    useEffect(() => {
      data = Array.from(initialData)
      setUpdateRate(Array.from(update_seconds))

      let high_speed_refresh = 500

      setIntervalX(setFunding.current,high_speed_refresh,(funding_refresh/high_speed_refresh))
      setIntervalX(setRealtime.current,high_speed_refresh,(realtime_refresh/high_speed_refresh))
      setIntervalX(setFutures.current,high_speed_refresh,(futures_refresh/high_speed_refresh))

      interval_realtime.current = setInterval(setRealtime.current, realtime_refresh)
      interval_funding.current = setInterval(setFunding.current, funding_refresh)
      interval_futures.current = setInterval(setFutures.current, futures_refresh)
      interval_ups.current = setInterval(setUPS.current, ups_refresh)

      const listener = EventEmitter.addListener('highlightRow',(values) => {
        let background_highlight_1 = '#0066cc'
        let background_highlight_2 = '#CC1111'
        let highlight_color = background_highlight_1
        let remove_highlight_id = false
        let remove_last_highlighted_id = false

        let highlighted_rows = data.filter( row => row.highlight_color )
        highlighted_rows.forEach(highlight_row => {
            if (highlight_row.id === values.id) remove_highlight_id = values.id

            if (highlight_row.last_highlighted === false) {
                remove_highlight_id = highlight_row.id
            }
            else
            if (highlight_row.last_highlighted) {
                remove_last_highlighted_id = highlight_row.id
                if (highlight_row.highlight_color === background_highlight_1) {
                    highlight_color = background_highlight_2
                }
            }
        })

        if (remove_last_highlighted_id) updateRow({id:remove_last_highlighted_id},{last_highlighted: false})
        if (remove_highlight_id) updateRow({id:remove_highlight_id},{highlight_color: false, last_highlighted: false})
        if (values.id !== remove_highlight_id) updateRow({id:values.id},{highlight_color: highlight_color,last_highlighted: true})

      })

      if (!test || test===HTX) HTX.get_expiry({symbols:htx_coin_futures,update_internal})


      /*
      const slow_down_refresh = setTimeout(() => {
        clearInterval(interval_realtime.current)
        clearInterval(interval_funding.current)
        interval_realtime.current = setInterval(setRealtime.current, 333)
        interval_funding.current = setInterval(setFunding.current, 1500)
        clearInterval(slow_down_refresh)
      },10000)
      */


      return () => {
        clearInterval(interval_realtime.current)
        clearInterval(interval_funding.current)
        clearInterval(interval_futures.current)
        //clearInterval(interval_ups.current)
        //clearInterval(slow_down_refresh)
        listener.remove()
      }
      // eslint-disable-next-line
    },[])

    return null
}

export default SocketManager

//SocketManager.whyDidYouRender = true;
/*
await mutex.current.runExclusive(async () => {
})
*/
    /*
    export const update_ticker = async(symbols,symbol_to_update,values,{internalData, setInternalData}) => {
        symbols.forEach (symbol => {
            if (symbol.ticker.toUpperCase() === symbol_to_update.toUpperCase())
            {
                updateRow(symbol,values,false,{internalData, setInternalData});
            }
        })
    }
    */

    
    export const status_symbols = (symbols,status) =>
    {
        for (let i = 0; i < symbols.length; i++)
        {
            let symbol = symbols[i]
            updateRow(symbol,{status: status})
        }
    }

    export const refresh_symbols = (symbols) =>
    {
        for (let i = 0; i < symbols.length; i++)
        {
            let symbol = symbols[i]
            updateRow(symbol,{refresh: tools.getUpdateTime()})
        }
    }

    export const updateRow = (symbol, values) =>
    {
        let modified = false
        let row = data.find( row => row.id === symbol.id )
        let keys = Object.keys(values)
        keys.forEach((key) => {
            if (key==='funding_rate' || key==='predicted_funding_rate')
            {
                modified = tools.updateColumn(row,key,values[key])
            }
            else if (key==='last')
            {
                tools.updateColumn(row,key,tools.dynamic_decimals(values[key]*symbol.multiplier))
            }
            else tools.updateColumn(row,key,values[key])
        })
        row.refresh = tools.getUpdateTime()
        if (modified) row.modified = tools.getUpdateTime()
        tools.mergeRows(data,row)
    }


    export const setIntervalX = (callback, delay, repetitions) => 
    {
        var x = 0
        var intervalID = setInterval(function () {
           callback()
           if (++x === repetitions) {
               clearInterval(intervalID)
           }
        }, delay)
    }