import React, { useContext } from 'react'
import 'fontsource-roboto'
import './App.css'
import { ConfigContext } from './context/ConfigProvider'
import ExchangeTable from './tables/ExchangeTable'
import Euro from './tables/Euro'
import { useParams } from 'react-router-dom'

function Exchanges() {

  const  { Styles } = useContext(ConfigContext)
  let exchanges = ['BITMEX','KUCOIN', 'BINANCE', 'BYBIT', 'DERIBIT', 
  'FTX', 'HUOBI', 'OKX','ASCENDEX','BITGET','DELTA','GATE.IO','PHEMEX','POLONIEX','COINEX']
  const params = useParams()
  if (params.exchange!==undefined) exchanges = [params.exchange.toUpperCase()]


  return (
    <Styles>
      <br></br><br></br><br></br>
        <Euro title='BITMEX EUR'/>
      <br></br>
      {
        exchanges.map((exchange) => (
          <React.Fragment key={exchange}>
            <br></br>
            <ExchangeTable title={exchange} exchange={exchange} />
            <br></br>
          </React.Fragment>
        ))
      }
    </Styles>
  )
  
}

export default Exchanges
