import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import {Table} from './Table'
import { RealtimeContext } from '../context/RealtimeDataProvider'
import { FuturesContext } from '../context/FuturesDataProvider'
import EventEmitter from '../tools/Events'

export default function ExchangeTable({title, exchange})
{
    const { realtimeData } = useContext(RealtimeContext)
    const { futuresData } = useContext(FuturesContext)

    //let highlight_funding = 0.25
    //let highlight_predicted_funding = 0.25

    // Perpetual	Index	Future	Spread	Exp	Days	Rate	Daily	Annual

    const columns = React.useMemo(
      () => [
        {
          Header: 'ID',
          accessor: 'id', // accessor is the 'key' in the data
        },
        {
          Header: 'Source',
          accessor: 'source',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color) {
              return (
              <div style={{ background: row.original.highlight_color,  color: '#FFF'}} 
              onClick={() => {EventEmitter.emit('highlightRow',row.values)}}
              >{value}</div>
              )
            }
            return (
              <div onClick={() => {EventEmitter.emit('highlightRow',row.values)}}
              >{value}</div>
            )
          }

        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color,  color: '#FFF' }} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: '',
          accessor: 'type_icon',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color ,  color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Last',
          accessor: 'last',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: ' Funding',
          accessor: 'funding_rate',
          Cell: ({ value }) => {
            return tools.format_funding(value)
          }
        },
        {
          Header: ' Predict',
          className: 'fixed',
          accessor: 'predicted_funding_rate',
          Cell: ({ value }) => {
            return tools.format_funding(value)
          }
        },
        {
          Header: 'Perpetual',
          accessor: 'related_last',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Index',
          accessor: 'index',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Spread',
          accessor: 'spread',
          sortType: 'basic',
          headerClassName: 'right',
          className: 'right',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Exp',
          accessor: 'expiration',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Days',
          accessor: 'days',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        
        {
          Header: 'Rate',
          accessor: 'expiration_rate',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Daily',
          accessor: 'daily_rate',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Annual',
          accessor: 'annual_rate',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'WS',
          accessor: 'status',
          className: 'fixed-width',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color ,  color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        }
      ],
      []
    )
    

    let realtime_data_table = tools.exchange_data_realtime(realtimeData,exchange)
    let futures_data_table = tools.exchange_data_realtime(futuresData,exchange)
    
    let table_data = []
    table_data.push(...realtime_data_table)
    table_data.push(...futures_data_table)

    return (
        <>
            <center><span className='spread text'>{title ? title : exchange}</span></center>
            {/* <center><Spread data={table_data}></Spread></center> */}
            <Table columns={columns} data={table_data}/>
        </>
    )

}



//FundingTable.whyDidYouRender = true;
