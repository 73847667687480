import React, { useContext, useState } from 'react'
import 'fontsource-roboto'
import './App.css'
import { ConfigContext, USDT } from './context/ConfigProvider'
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom'
import SuperOpportunitiesTable from './tables/SuperOpportunitiesTable'
import SuperOpportunitiesTableMobile from './tables/SuperOpportunitiesTableMobile'
import axios from 'axios'
import Button from '@mui/material/Button';
import {Toggle, purge_local_storage} from './components/toggle'
import { NumberField } from './components/NumberField'
import { RangeField } from './components/RangeField'
import {Switch} from './components/Switch';
import {exchanges} from './context/ConfigProvider'
import useLocalStorage from './tools/useLocalStorage'
import {Status} from './components/Status';
import { UPSContext } from './context/UPSDataProvider'

const url_prefix = () => {
  if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === 'development') {
      return ''
  } else {
      return ''
  }
}

function SuperOpportunities() {

  const { updateRate } = useContext(UPSContext)
  const {config, setConfig, Styles, funding_exceptions} = useContext(ConfigContext)
  const [investment, setInvestment] = useLocalStorage('investment',1000)

  let initial_symbols = []
  let id = 0
  config.symbols.forEach(symbol => {
      id++
      initial_symbols.push({
        id: id,
        text: symbol.symbol,
        active: true
      })
  })
  
  id = 0
  let table_columns = []
  let columns = ['FUNDING','PREDICTED','EXCHANGES','ACCUMULATED','ENTRY + ACCUMULATED','QUANTITY','ENTRY $','PNL $','NEXT HOURS']
  columns.forEach(column => {
    id++
    table_columns.push({
      id: id,
      text: column,
      active: true
    })
  })

  purge_local_storage(table_columns,'filtered_columns')
  purge_local_storage(initial_symbols,'filtered_symbols')

  const [filteredColums, setFilteredColums] = useLocalStorage('filtered_columns',table_columns)
  const [filteredSymbols, setFilteredSymbols] = useLocalStorage('filtered_symbols',initial_symbols)
  const [filteredExchanges, setfilteredExchanges] = useLocalStorage('filtered_exchanges',exchanges)

  const [mobile, setMobile] = useState([{id: 1, text: 'DESKTOP', active: true},
                                        {id: 2, text: 'MOBILE', active: false}])

  const [enable_filters, setEnable_filters] = useLocalStorage('enable_filters',[{id: 1, text: 'ENABLE FILTERS', active: true},
                                                        {id: 2, text: 'DISABLE FILTERS', active: false}])

  const [group_by_symbols, setGroup_by_symbols] = useLocalStorage('group_by_symbols',[{id: 1, text: 'ENABLE GROUP BY SYMBOLS', active: false},
  {id: 2, text: 'DISABLE GROUP BY SYMBOLS', active: true}])

  const [limit, setLimit] = useLocalStorage('limit',10)
                                        
  const [entry, setEntry] = useLocalStorage('entry',[-10,10])
  const [firstSettlement, setFirstSettlement] = useLocalStorage('firstSettlement',[-5,10])
  const [secondSettlement, setSecondSettlement] = useLocalStorage('secondSettlement',[1,10])
  const [thirdSettlement, setThirdSettlement] = useLocalStorage('thirdSettlement',[-5,10])
  const [fourthSettlement, setFourthSettlement] = useLocalStorage('fourthSettlement',[-5,10])

  const handleTextInputChange = event => {
    setInvestment(event.target.value)
  }
  const history = useHistory()

  const handleClick = () => {
    axios.get('https://www.fundingarb.com.ar/hot_symbols')
    .then(res => {
      let symbols = []
      let new_filtered_symbols = []
      let id = 0
      res.data.forEach(symbol => {
          id++
          symbols.push({
            symbol: symbol,
            highlight_funding: 0.10,
            highlight_predicted_funding: 0.10
          })
          let active = true
          let symbol_filtered = filteredSymbols.find(x => x.text===symbol)
          if (symbol_filtered!=undefined) active = symbol_filtered.active
          new_filtered_symbols.push({
            id: id,
            text: symbol,
            active: active
          })
      })
      setConfig({symbols: symbols})
      setFilteredSymbols(new_filtered_symbols)
      let prefix = url_prefix()
      history.push(`${prefix}/super_opportunities`)
      window.location.reload()
    })
  }
  let is_mobile = false
  if (mobile[1].active) is_mobile = true

  return (
    <Styles>
      <br></br>
      <center>
        <Status exchanges={updateRate} ></Status>
      </center>
      <br></br>

      <center>
        <table border={0}>
          <tr>
            <td>

              {
              /*
              <TextField id="investment" label="Investment" variant="standard"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} defaultValue="1000"
              onChange={handleTextInputChange} />
              */
              }

              <NumberField title="INVESTMENT" number={investment} setNumber={setInvestment} step="1"></NumberField>
              <Toggle elements={filteredSymbols} setElements={setFilteredSymbols} />
              <Toggle elements={filteredExchanges} setElements={setfilteredExchanges} />
              <Toggle elements={filteredColums} setElements={setFilteredColums} />
              <table border={0}>
                <tr>
                  <td style={{paddingRight: 1 + 'em'}} >   <RangeField title="ENTRY" range={entry} setRange={setEntry} step="0.1"></RangeField></td>
                  <td style={{paddingRight: 1 + 'em'}}  >  <RangeField title="FIRST ARB" range={firstSettlement} setRange={setFirstSettlement} step="0.1"></RangeField></td>
                  <td style={{paddingRight: 1 + 'em'}}  >  <RangeField title="SECOND ARB" range={secondSettlement} setRange={setSecondSettlement} step="0.1"></RangeField></td>
                  <td style={{paddingRight: 1 + 'em'}}  >  <RangeField title="THIRD ARB" range={thirdSettlement} setRange={setThirdSettlement} step="0.1"></RangeField></td>
                  <td style={{paddingRight: 1 + 'em'}}  >  <RangeField title="FOURTH ARB" range={fourthSettlement} setRange={setFourthSettlement} step="0.1"></RangeField></td>
                  <td style={{paddingRight: 1 + 'em'}}  >  <NumberField title="ROW LIMIT" number={limit} setNumber={setLimit} step="1"></NumberField></td>
                </tr>
              </table>
              { /* <span className={'toggle'}>FILTERS: &nbsp;</span> */ }
              <Switch options={enable_filters} setOptions={setEnable_filters} />
              <Switch options={group_by_symbols} setOptions={setGroup_by_symbols} />

            </td>
          </tr>
        </table>
      </center>





      <br></br>
        <br></br>
        { is_mobile && <SuperOpportunitiesTableMobile title={'SORTED BY SECOND ARB %'} investment={investment} types={[USDT]} filteredSymbols={filteredSymbols} filteredExchanges={filteredExchanges}
        funding_exceptions={funding_exceptions} filtered_columns={filteredColums} 
        enable_filters={enable_filters} group_by_symbols={group_by_symbols} sort_by={2} /> }
        
        { !is_mobile && <SuperOpportunitiesTable title={'SORTED BY SECOND ARB %'} investment={investment} types={[USDT]} filteredSymbols={filteredSymbols} filteredExchanges={filteredExchanges}
        funding_exceptions={funding_exceptions} filtered_columns={filteredColums} filters={{entry,firstSettlement,secondSettlement,thirdSettlement,fourthSettlement,limit}}  
        enable_filters={enable_filters} group_by_symbols={group_by_symbols} sort_by={2} /> }
        
        <br></br><br></br>
        
        { is_mobile && <SuperOpportunitiesTableMobile title={'SORTED BY FIRST ARB %'} investment={investment} types={[USDT]} filteredSymbols={filteredSymbols} filteredExchanges={filteredExchanges}
        funding_exceptions={funding_exceptions} filtered_columns={filteredColums} 
        enable_filters={enable_filters} group_by_symbols={group_by_symbols} sort_by={1} /> }
        
        { !is_mobile && <SuperOpportunitiesTable title={'SORTED BY FIRST ARB %'} investment={investment} types={[USDT]} filteredSymbols={filteredSymbols} filteredExchanges={filteredExchanges}
        funding_exceptions={funding_exceptions} filtered_columns={filteredColums} filters={{entry,firstSettlement,secondSettlement,thirdSettlement,fourthSettlement,limit}}  
        enable_filters={enable_filters} group_by_symbols={group_by_symbols} sort_by={1} /> }
        
        <br></br><br></br>
        
        { is_mobile && <SuperOpportunitiesTableMobile title={'SORTED BY ENTRY %'} investment={investment} types={[USDT]} filteredSymbols={filteredSymbols} filteredExchanges={filteredExchanges}
        funding_exceptions={funding_exceptions} filtered_columns={filteredColums} 
        enable_filters={enable_filters} group_by_symbols={group_by_symbols} sort_by={0} /> }
        
        { !is_mobile && <SuperOpportunitiesTable title={'SORTED BY ENTRY %'} investment={investment} types={[USDT]} filteredSymbols={filteredSymbols} filteredExchanges={filteredExchanges}
        funding_exceptions={funding_exceptions} filtered_columns={filteredColums} filters={{entry,firstSettlement,secondSettlement,thirdSettlement,fourthSettlement,limit}}  
        enable_filters={enable_filters} group_by_symbols={group_by_symbols} sort_by={0} /> }



        <br></br>
        <center>
        <Button onClick={handleClick}>Load Hot Symbols</Button> 
        </center>
    </Styles>
  )
  
}

export default SuperOpportunities