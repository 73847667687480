import axios from 'axios'
import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import { COIN,USDT } from '../context/ConfigProvider'
import ConsoleHelper from '../tools/ConsoleHelper'

export const SOURCE = 'HYPE'

/*
*/

export function Hype(symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    

    useEffect(() => {

        const connect = () => {
            let ws = new ZSocket('wss://api-ui.hyperliquid.xyz/ws', NO_COMPRESSION); webSocket.current = ws
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    if (symbol.type===COIN)
                    {
                    let coin_symbol = symbol.source_symbol.symbol
                    ws.sendJson({"method":"subscribe","subscription":{"type":"activeAssetCtx","coin":coin_symbol}})
                    }
                })
                ping_interval.current = setInterval(() => {
                    ws.sendJson({ "method": "ping" })
                }, 20000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (jsonMessage) => {
                if (Object.prototype.hasOwnProperty.call(jsonMessage,'channel')){
                    if (jsonMessage.channel == 'activeAssetCtx')
                    {
                        if (Object.prototype.hasOwnProperty.call(jsonMessage,'data')){
                            let data = jsonMessage.data
                            let coin
                            if (Object.prototype.hasOwnProperty.call(data,'coin')) coin = data.coin
                            if (Object.prototype.hasOwnProperty.call(data,'ctx')){
                                let ctx = data.ctx
                                symbols.forEach((symbol) => {
                                    if (symbol.source_symbol.symbol==coin)
                                    {
                                        update_internal(symbols,symbol.symbol,{
                                            funding_rate: parse100(ctx.funding*8),
                                            last: parse(ctx.midPx),
                                            mark: parse(ctx.markPx)
                                        })
                                    }

                                    /*
                                    if (markets[symbol.symbol]) {

                                    }
                                    */
                                })
                                
                            }
                        }
                    }
                    else
                    {
                        ConsoleHelper(SOURCE,jsonMessage)
                    }
                }
            }
        }
        connect()

        return () => {
        }

    }, [symbols,update_internal,parse,parse100])

    return webSocket.current
}

