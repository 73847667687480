import React, {useContext, useState} from 'react'
import { ConfigContext } from './ConfigProvider'

export const RealtimeContext = React.createContext()

export const RealtimeDataProvider = ({ children }) => {

    const {initialData} = useContext(ConfigContext)
    const [realtimeData, setRealtimeData] = useState(Array.from(initialData))

    return (
        <RealtimeContext.Provider value={{
            realtimeData, setRealtimeData
        }}>
            { children }
        </RealtimeContext.Provider>
    )
}

//DataProvider.whyDidYouRender = true;

