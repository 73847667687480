import pako from 'pako'
import { Mutex } from 'async-mutex'
import * as BITMEX from '../sources/bitmex'
import * as BINANCE from '../sources/binance'
import * as BYBIT from '../sources/bybit'
import * as OKX from '../sources/okx'
import * as HTX from '../sources/htx'
import * as FTX from '../sources/ftx'
import * as DERIBIT from '../sources/deribit'
import * as KUCOIN from '../sources/kucoin'
import * as COINEX from '../sources/coinex'
import ConsoleHelper from './ConsoleHelper'
import {exchanges,UTC_ALL,UTC_0_8_16,UTC_4_12_20, UNKNOWN} from '../context/ConfigProvider'

import { COIN, USDT, FUTURES, PERPETUAL } from '../context/ConfigProvider'

const mutex = new Mutex()
export default mutex
Object.freeze(mutex)

export function addIdToSymbols (symbol_list) {
    symbol_list.forEach(symbol => {
        symbol['id'] = symbol_list.indexOf(symbol) + 1
    })
}

export function setRelatedSymbols(symbol_list) {
    symbol_list.forEach(symbol => {
        if (symbol!=undefined)
        {
            if (symbol.contract!=undefined)
            {
                if (symbol.contract === FUTURES) {
                    if (symbol.source!=undefined && symbol.source_symbol!=undefined) 
                    {
                        let perpetual_symbol = symbol_list.find(x => (
                            x.source_symbol === symbol.source_symbol &&
                            x.source.SOURCE === symbol.source.SOURCE &&
                            x.contract === PERPETUAL))
            
                        if (perpetual_symbol !== undefined) {
                            symbol['related_symbol_id'] = perpetual_symbol.id
                        }
                    }
                }
            }
        }

    })
}

export function set_multiplier(symbol_list) {
    symbol_list.forEach(symbol => {
        if (symbol.source === BYBIT && symbol.symbol === 'VETUSDT') {
            symbol['multiplier'] = 0.1
        }
        else symbol['multiplier'] = 1
    })
}

export function find_source(source) {
    if (source === BITMEX.SOURCE) return BITMEX
    if (source === BINANCE.SOURCE) return BINANCE
    if (source === BYBIT.SOURCE) return BYBIT
    if (source === OKX.SOURCE) return OKX
    if (source === HTX.SOURCE) return HTX
    if (source === FTX.SOURCE) return FTX
    if (source === DERIBIT.SOURCE) return DERIBIT
    if (source === KUCOIN.SOURCE) return KUCOIN
    if (source === COINEX.SOURCE) return COINEX
}

export function find_type (type) {
    if (type === 'COIN') return COIN
    if (type === 'USDT') return USDT
}

export function find_source_symbol(source_symbol, symbols) {
    let toReturn = undefined
    symbols.forEach(symbol => {
        if (symbol.symbol === source_symbol) {
            toReturn = symbol
            return
        }
    })
    return toReturn
}
 
export function symbol_data_funding(full_data, filter_symbol, types) {
    if (full_data === null) return
    let data = []
    full_data.forEach(row => {
        if (row.source_symbol === filter_symbol) {
            if (types.includes(row.type)) {
                if (row.last || row.funding_rate || row.predicted_funding_rate)
                    data.push(row)
            }
        }
    })
    return data
}

const remove_duplicates = (arr) => {
    return [...new Set(arr)]
}




export function exchange_data_realtime(full_data, filter_exchange) {
    if (full_data === null) return
    let data = []
    full_data.forEach(row => {
        if (row.source === filter_exchange) {
            if (row.last)
                data.push(row)
        }
    })
    return data
}

export function euro_price(full_data, exchange, usd_symbol, eur_symbol) {
    if (full_data === null) return
    let usd_symbol_price = 0
    let eur_symbol_price = 0
    full_data.forEach(row => {
        if (row.source === exchange) {
            if (row.last) {
                if (row.symbol === usd_symbol) usd_symbol_price = row.last
                if (row.symbol === eur_symbol) eur_symbol_price = row.last
            }
        }
    })
    return (usd_symbol_price / eur_symbol_price)
}

export function symbol_data_realtime(full_data, filter_symbol, types) {
    if (full_data === null) return
    let data = []
    full_data.forEach(row => {
        if (row.source_symbol === filter_symbol) {
            if (types.includes(row.type)) {
                if (row.last)
                    data.push(row)
            }
        }
    })
    return data
}

export function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}

export function convertDateMonth(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1)].join('/')
}

export function get_number_of_days(start, end) {
    const date1 = new Date(start)
    const date2 = new Date(end)
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime()
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay)
    return diffInDays
}

export function parseDate(input, format) {
    format = format || 'yyyy-mm-dd'
    var parts = input.match(/(\d+)/g),
        i = 0, fmt = {}
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++ })

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']])
}

export function getExchanges(symbols) {
    const uniqueArr = [...new Set(symbols.map(symbol => symbol.source))]
    return uniqueArr
}

export function get_futures_data(data) {
    let futures_data = data.filter(symbol => symbol.contract === FUTURES)

    futures_data.forEach(symbol => {
        let row_index = futures_data.indexOf(symbol)
        let related_symbol = data.find(row => row.id === symbol.related_symbol_id)
        let spread = 0
        if (symbol.index) spread = symbol.last - symbol.index
        else spread = symbol.last - related_symbol.last

        let days = get_number_of_days(Date.now(), symbol.expiry)
        //let expiration_rate = spread / symbol.last
        let expiration_rate_2 = spread / related_symbol.last

        //let daily_rate = expiration_rate / days
        let daily_rate_2 = expiration_rate_2 / days

        //let annual_rate = daily_rate * 365
        let annual_rate_2 = daily_rate_2 * 365

        futures_data[row_index].future_last = symbol.last
        if (!symbol.index) futures_data[row_index].related_last = related_symbol.last
        futures_data[row_index].expiration = convertDateMonth(symbol.expiry)
        futures_data[row_index].spread = dynamic_decimals(spread)
        futures_data[row_index].days = days
        futures_data[row_index].expiration_rate = format_rate(expiration_rate_2)
        futures_data[row_index].daily_rate = format_rate(daily_rate_2)
        futures_data[row_index].annual_rate = format_rate(annual_rate_2)
        futures_data[row_index].funding_rate = ''
        futures_data[row_index].predicted_funding_rate = ''

        /*
        if (symbol.source === 'BITMEX' && symbol.symbol === 'ETHUSDU21')
        {
            
            let push_object = {
                id: symbol.id+1000,
                source: symbol.source,
                source_symbol: symbol.source_symbol,
                type: symbol.type,
                symbol: symbol.symbol,
                status: symbol.status,
                modified: symbol.modified,
                refresh: symbol.refresh,
                contract: symbol.contract,
                related_symbol_id: symbol.related_symbol_id,
                funding_rate: '',
                predicted_funding_rate: '',
                days: days,
                last: symbol.last,
                related_last: related_symbol.last,
                spread: dynamic_decimals(spread),
                future_last: symbol.last,
                expiration: convertDateMonth(symbol.expiry),
                expiration_rate: format_rate(expiration_rate_2),
                daily_rate: format_rate(daily_rate_2),
                annual_rate: format_rate(annual_rate_2)
            }
            futures_data.push(push_object)
            //futures_data[push_index].id = futures_data[row_index].id+1000
            //futures_data[push_index].expiration_rate = new String(format_rate(expiration_rate_2))
        }
        */

    })
    return Array.from(futures_data)
}

export function format_rate(rate) {
    return (parseFloat(rate) * 100).toFixed(2) + '%'
}

export function symbol_data_positions(full_data,positions) {
    if (full_data === null) return
    let data = []

    if (positions!=undefined)
    {
        positions.forEach(position => {
        let long_symbol = full_data.find(x => x.source===position.long_exchange && x.source_symbol===position.symbol && x.type === USDT)
        let short_symbol = full_data.find(x => x.source===position.short_exchange && x.source_symbol===position.symbol && x.type === USDT)

        if (long_symbol!=undefined && short_symbol!=undefined)
        {
            if (long_symbol.last!=undefined && long_symbol.last!='' && short_symbol.last!=undefined && short_symbol.last!='')
            {


                let symbol = position.symbol
                let quantity = position.quantity
                let long_exchange = position.long_exchange
                let long_entry = parseFloat(position.long_entry)
                let short_exchange = position.short_exchange
                let short_entry = parseFloat(position.short_entry)
                let long_last = parseFloat(long_symbol.last)
                let short_last = parseFloat(short_symbol.last)
                let entry_pnl = round((short_entry*quantity)-(long_entry*quantity))
                let exit_pnl = round((long_last*quantity)-(short_last*quantity))
                let pnl = round(exit_pnl+entry_pnl)

              data.push({
                symbol: symbol,
                 quantity :quantity,
                 long_exchange: long_exchange,
                 long_entry :long_entry,
                 short_exchange: short_exchange,
                 short_entry :short_entry,
                 long_last :long_last,
                 short_last :short_last,
                 entry_pnl : entry_pnl,
                 exit_pnl :exit_pnl,
                 pnl: pnl
                 })

            }

        }

        })
    }

    return data
}

export const getUpdateTime = () => {
    var d = new Date(),
        dformat = [d.getHours(),
        (d.getMinutes() < 10) ? '0' + d.getMinutes() : String(d.getMinutes())
        ].join(':')
    return dformat
}

export const url_prefix = () => {
    if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === 'development') {
        return ''
    } else {
        return ''
        //return '/cripto-info'
    }
}

const gray = 'gray'
const light_green = '#00AA00'
const strong_green = '#00DD00'
const light_yellow = '#EEEE00'
const strong_yellow = '#FEEF00'

const light_red = '#DD7777'
const strong_red = '#EE8888'

const positive_color = '#88CC88'
const negative_color = '#EE8888'

export const format_funding = (value,addition = '') => {
    if (0 <= value && value <= 0.01) return <div style={{ color: gray }}>&nbsp;{value}{addition}</div>
    if (0.01 < value && value <= 0.05) return <div style={{ color: light_green }}>&nbsp;{value}{addition}</div>
    if (0.05 < value && value <= 0.12) return <div style={{ color: strong_green }}>&nbsp;{value}{addition}</div>
    if (0.12 < value && value <= 0.20) return <div style={{ color: light_yellow }}>&nbsp;{value}{addition}</div>
    if (value > 0.20) return <div style={{ color: strong_yellow, fontWeight: 'bold' }}>&nbsp;{value}{addition}</div>

    // Negative values
    if (0 >= value && value >= -0.01) return <div style={{ color: gray }}>{value}{addition}</div>
    if (-0.01 > value && value >= -0.05) return <div style={{ color: light_green }}>{value}{addition}</div>
    if (-0.05 > value && value >= -0.12) return <div style={{ color: strong_green }}>{value}{addition}</div>
    if (-0.12 > value && value >= -0.20) return <div style={{ color: light_yellow }}>{value}{addition}</div>
    if (value < -0.20) return <div style={{ color: strong_yellow, fontWeight: 'bold' }}>{value}{addition}</div>

    return value
}

export const format_positive_funding = (value,addition = '',missing = false) => {
    if (missing) return <div style={{ color: '#666666' }}>&nbsp;{value}{addition}</div>

    if (0 <= value && value <= 0.01) return <div style={{ color: gray }}>&nbsp;{value}{addition}</div>
    if (0.01 < value && value <= 0.05) return <div style={{ color: light_green }}>&nbsp;{value}{addition}</div>
    if (0.05 < value && value <= 0.12) return <div style={{ color: strong_green }}>&nbsp;{value}{addition}</div>
    if (0.12 < value && value <= 0.20) return <div style={{ color: light_yellow }}>&nbsp;{value}{addition}</div>
    if (value > 0.20) return <div style={{ color: strong_yellow, fontWeight: 'bold' }}>&nbsp;{value}{addition}</div>

    // Negative values
    if (0 >= value && value >= -0.01) return <div style={{ color: gray }}>{value}{addition}</div>
    if (-0.01 > value && value >= -0.05) return <div style={{ color: light_red }}>{value}{addition}</div>
    if (-0.05 > value && value >= -0.12) return <div style={{ color: light_red }}>{value}{addition}</div>
    if (-0.12 > value && value >= -0.20) return <div style={{ color: strong_red }}>{value}{addition}</div>
    if (value < -0.20) return <div style={{ color: strong_red, fontWeight: 'bold' }}>{value}{addition}</div>

    return value
}


export const format_currency = (value,addition = '',missing = false) => {
    if (missing) return <div style={{ color: '#666666' }}>&nbsp;$ {value}{addition}</div>
    if (value > 0) return <div style={{ color: positive_color }}>&nbsp;$ {value}{addition}</div>
    if (value == 0) return <div style={{ color: gray }}>&nbsp;$ {value}{addition}</div>
    if (value < 0) return <div style={{ color: negative_color }}>&nbsp;$ {value}{addition}</div>
    return value
}



export const mergeRows = (data, new_row) => {
    let rowToDelete = data.find(row => row.id === new_row.id)
    const index = data.indexOf(rowToDelete)
    if (index > -1) {
        data.splice(index, 1)
    }
    data.push(new_row)
    return data
}

export const purge_listed_symbols = (data,source,listed_symbols) =>
{
    let symbols = []
    listed_symbols.forEach(listed_symbol => {symbols.push(listed_symbol)})
    data.filter(e => {return e.source==source}).forEach(row => {
        if (row.last==='' && row.funding_rate==='') {
            remove_element(symbols,row.symbol)
        }
    })
    symbols = symbols.filter(only_unique)
    return symbols
}

export const remove_element = (array,element) =>
{
    let index = array.indexOf(element)
    if (index >= 0) {
        array.splice( index, 1 )
    }
}

export const only_unique = (value, index, array) => {
    return array.indexOf(value) === index;
}

export const getSymbol = (values, symbols) => {
    return symbols.find(symbol => symbol.id === values.id)
}

export const updateColumn = (row, name, value) => {
    if (row[name] !== value) {
        row[name] = value
        return true
    }
    return false
}

export const find_highlighted_rows = (table_data) => {
    const elementosFiltrados = table_data.filter(elemento => elemento.hasOwnProperty('highlight_color'))

    if (elementosFiltrados.length > 1) {
      // Encuentra el elemento con el mayor y el menor "last"
      const mayorLast = Math.max(...elementosFiltrados.map(elemento => elemento.last));
      const menorLast = Math.min(...elementosFiltrados.map(elemento => elemento.last));
    
      // Realiza la resta
      const resultado = mayorLast - menorLast;
    
      return resultado.toFixed(4)
    }
    return ''
}

export function find_symbol_from_source(symbol_list, source, type, contract_type) {
    let symbols = []
    symbol_list.forEach(symbol => {
        if (symbol.source === source) {
            if (type.includes(symbol.type)) {
                if (contract_type === undefined) {
                    symbols.push(symbol)
                }
                else {
                    if (contract_type.includes(symbol.contract))
                        symbols.push(symbol)
                }
            }
        }
    })
    return symbols
}

export function four_decimals(value) {
    if (value !== undefined) return (parseFloat(value) * 100).toFixed(4)
    return ''
}

export function two_decimals(value) {
    if (value !== undefined) return (parseFloat(value)).toFixed(2)
    return ''
}

export function dynamic_decimals(value) {
    if (value !== undefined) {
        if (parseFloat(value) > 5) return parseFloat(value).toFixed(2)
        else if (parseFloat(value) > 0 && parseFloat(value) < 0.0001) return parseFloat(value).toFixed(8)
        else return parseFloat(value).toFixed(4)
    }
    return ''
}

export function dynamic_decimals_by_100(value) {
    if (value !== undefined) {
        if (parseFloat(value) * 100 > 5) return (parseFloat(value) * 100).toFixed(2)
        else if (parseFloat(value) * 100 > 0 && parseFloat(value) * 100 < 0.0001) return (parseFloat(value) * 100).toFixed(8)
        else return (parseFloat(value) * 100).toFixed(4)
    }
    return ''
}


export function dynamic_decimals_e4(value) {
    if (value !== undefined) {
        if (parseFloat(value / 100 / 100) > 5) return parseFloat(value / 100 / 100).toFixed(2)
        else if (parseFloat(value / 100 / 100) > 0 && parseFloat(value / 100 / 100) < 0.0001) return parseFloat(value / 100 / 100).toFixed(8)
        else return parseFloat(value / 100 / 100).toFixed(4)
    }
    return ''
}

export function dynamic_decimals_e7(value) {
    if (value !== undefined) {
        if (parseFloat(value * 100 * 8) > 5) return parseFloat(value * 100 * 8).toFixed(2)
        else if (parseFloat(value * 100 * 8) > 0 && parseFloat(value * 100 * 8) < 0.0001) return parseFloat(value * 100 * 8).toFixed(8)
        else return parseFloat(value * 100 * 8).toFixed(4)
    }
    return ''
}


export function four_decimals_e6(value) {
    if (value !== undefined) return (parseFloat(value / 100 / 100)).toFixed(4)
    return ''
}

export function two_decimals_e4(value) {
    if (value !== undefined) return (parseFloat(value / 100 / 100)).toFixed(2)
    return ''
}

export function data_strategy(full_data, types) {
    if (full_data === null) return
    let initial_data = []
    let data = []
    let all_symbols = []
    full_data.forEach(row => {
        if (types.includes(row.type)) {
            if (row.last && row.funding_rate)
                {
                    initial_data.push(row)
                    all_symbols.push(row.source_symbol)
                }
        }
    })
    let symbols = remove_duplicates(all_symbols)
    symbols.forEach(symbol => {
        let symbol_rows = initial_data.filter(row => {
            return row.source_symbol === symbol;
        })
        for (let long=0;long<symbol_rows.length;long++)
        {
            for (let short=0;short<symbol_rows.length;short++)
            {
                if (long==short) continue

                let funding_arbitrage = parseFloat(symbol_rows[short].funding_rate-symbol_rows[long].funding_rate).toFixed(4)
                

                let long_missing_predicted = false
                let short_missing_predicted = false
                
                let short_predicted_funding_rate = symbol_rows[short].predicted_funding_rate
                if (short_predicted_funding_rate==undefined ||
                    short_predicted_funding_rate==0 ||
                    short_predicted_funding_rate=='')
                {
                    short_predicted_funding_rate = symbol_rows[short].funding_rate
                    short_missing_predicted = true
                }
            
                let long_predicted_funding_rate = symbol_rows[long].predicted_funding_rate
                if (long_predicted_funding_rate == undefined ||
                    long_predicted_funding_rate == 0 ||
                    long_predicted_funding_rate == '' )
                {
                    long_predicted_funding_rate = symbol_rows[long].funding_rate
                    long_missing_predicted = true
                }
                
                let predicted_arbitrage = parseFloat(short_predicted_funding_rate-long_predicted_funding_rate).toFixed(4)


                if (funding_arbitrage>0)
                {
                        data.push({
                        symbol: symbol_rows[long].source_symbol,
                        long_exchange: symbol_rows[long].source,
                        long_funding_rate: symbol_rows[long].funding_rate,
                        long_predicted_funding_rate: long_predicted_funding_rate,
                        long_last: symbol_rows[long].last,
                        short_exchange: symbol_rows[short].source,
                        short_funding_rate: symbol_rows[short].funding_rate,
                        short_predicted_funding_rate: short_predicted_funding_rate,
                        short_last: symbol_rows[short].last,
                        funding_arbitrage: funding_arbitrage,
                        predicted_arbitrage: predicted_arbitrage,
                        long_missing_predicted: long_missing_predicted,
                        short_missing_predicted: short_missing_predicted,
                        exchanges: symbol_rows.length
                    })
                }
            }
        }
    })
    return data
}

export const get_funding_hours = (source_symbol,exchange_name,funding_exceptions) => {
    let funding_hours_return

    let funding_exception = funding_exceptions.find(f => {return f.exchange === exchange_name})
    if (funding_exception!=undefined)
    {
        let symbol = funding_exception.symbols.find(s => {return s.symbol===source_symbol})
        if (symbol !=undefined )
            {
                return symbol.funding_settlements
            }
    }

    let exchange = exchanges.find(e => {return e.text===exchange_name})
    if (exchange!=undefined)
    {
        switch(exchange.funding_hours)
        {
            case UTC_ALL: 
            return [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
            case UTC_0_8_16: 
            return [0,8,16]
            case UTC_4_12_20: 
            return [4,12,20]
            case UNKNOWN: 
            return
        }
    }
}

export const adjustGMT = (hour,GMT) =>
{
    return ((hour + GMT + 24) % 24)
}

export const get_next_four_funding_hours = (hour) => {
    let funding_hours = [0,4,8,12,16,20]
    let all_hours = []
    let next_funding_hours = []
    for (let h=0;h<48;h++)
    {
        let funding = false
        if (funding_hours.includes(h%24)) funding=true
        all_hours.push({hour: h%24, funding: funding})
    }
    let found_time = false
    let distance = 1
    let found_funding = false
    for (let h=0;h<all_hours.length;h++)
    {
        if (!found_time) {
            if (all_hours[h].hour == hour) found_time = true
        }
        else {
            if (all_hours[h].funding) {
                found_funding = true
                next_funding_hours.push(all_hours[h].hour)
            }
            else {
                if (!found_funding) distance++
            }
        }
    }
    let to_return = []
    for (let c=0;c<4;c++)
    {
        to_return.push(next_funding_hours[c])
    }
    return {next_hours: to_return, distance: distance}
}

const get_next_fundings = (hour,funding_hours,funding_rate,predicted_funding_rate) =>
{
    let all_hours = []
    let next_funding_hours = []
    if (funding_hours==undefined) return next_funding_hours
    for (let h=0;h<48;h++)
    {
        let funding = false
        if (funding_hours.includes(h%24)) funding=true
        all_hours.push({hour: h%24, funding: funding})
    }

    let found_time = false
    let found_funding_time = false
    for (let h=0;h<all_hours.length;h++)
    {
        if (!found_time) {
            if (all_hours[h].hour == hour) found_time = true
        }
        else {
            let funding = 0
            if (all_hours[h].funding) {
                if (!found_funding_time) {
                    found_funding_time = true
                    funding = parseFloat(funding_rate)
                }
                else
                {
                    funding = parseFloat(predicted_funding_rate)
                }
            }
            next_funding_hours.push({hour: all_hours[h].hour, funding: funding})
        }
    }
    return next_funding_hours
}

const get_funding_arbitrages = (hours,short_next_fundings,long_next_fundings) => {
    let short_funding_sum = get_single_funding_sum(hours,short_next_fundings)
    let long_funding_sum = get_single_funding_sum(hours,long_next_fundings)
    return (short_funding_sum-long_funding_sum)
}

const get_single_funding_sum = (hours,next_funding_hours) => {
    let funding_sum = 0
    if (next_funding_hours==undefined) return funding_sum
    for (let h=0;h<hours;h++)
    {
        if (next_funding_hours[h]!=undefined && next_funding_hours[h].funding!=undefined) 
            funding_sum+=next_funding_hours[h].funding
    }
    return funding_sum
}


export function data_super_opportunities(full_data, types,funding_exceptions,date,next_funding_hours,filters, enable_filters) {
    if (full_data === null) return
    let filters_enabled = false
    if (enable_filters[0].active) filters_enabled = true

    let rows = 0
    let initial_data = []
    let data = []
    let all_symbols = []
    full_data.forEach(row => {
        if (types.includes(row.type)) {
            if (row.last && row.funding_rate)
                {
                    initial_data.push(row)
                    all_symbols.push(row.source_symbol)
                }
        }
    })
    let symbols = remove_duplicates(all_symbols)
    symbols.forEach(symbol => {
        let symbol_rows = initial_data.filter(row => {
            return row.source_symbol === symbol;
        })
        for (let long=0;long<symbol_rows.length;long++)
        {
            for (let short=0;short<symbol_rows.length;short++)
            {
                //console.log("combination L-"+symbol_rows[long].source+" S-"+symbol_rows[short].source)
                if (long==short) continue

                let long_missing_predicted = false
                let short_missing_predicted = false

                let short_funding_rate = symbol_rows[short].funding_rate
                let short_predicted_funding_rate = symbol_rows[short].predicted_funding_rate
                /* Fix Kraken 1 hour Funding */
                if (symbol_rows[short].source==='KRAKEN') {
                    short_funding_rate = parseFloat(short_funding_rate)/8
                    short_predicted_funding_rate = parseFloat(short_predicted_funding_rate)/8
                    short_missing_predicted = true
                }
                if (short_predicted_funding_rate==undefined ||
                    short_predicted_funding_rate==0 ||
                    short_predicted_funding_rate=='')
                {
                    short_predicted_funding_rate = symbol_rows[short].funding_rate
                    short_missing_predicted = true
                }

                let long_funding_rate = symbol_rows[long].funding_rate
                let long_predicted_funding_rate = symbol_rows[long].predicted_funding_rate
                /* Fix Kraken 1 hour Funding */
                if (symbol_rows[long].source==='KRAKEN') {
                    long_funding_rate = parseFloat(long_funding_rate)/8
                    long_predicted_funding_rate = parseFloat(long_predicted_funding_rate)/8
                    long_missing_predicted = true
                }
                if (long_predicted_funding_rate == undefined ||
                    long_predicted_funding_rate == 0 ||
                    long_predicted_funding_rate == '' )
                {
                    long_predicted_funding_rate = symbol_rows[long].funding_rate
                    long_missing_predicted = true
                }

                let short_funding_hours = get_funding_hours(symbol_rows[short].source_symbol,symbol_rows[short].source,funding_exceptions)
                let long_funding_hours = get_funding_hours(symbol_rows[long].source_symbol,symbol_rows[long].source,funding_exceptions)

                let current_hour = date.getUTCHours()
                let short_next_fundings = get_next_fundings(current_hour,short_funding_hours,short_funding_rate,short_predicted_funding_rate)
                let long_next_fundings = get_next_fundings(current_hour,long_funding_hours,long_funding_rate,long_predicted_funding_rate)

                let entry_arb_percent = (round(100-(parseFloat(symbol_rows[long].last)/parseFloat(symbol_rows[short].last)*100))).toFixed(2)

                let range_hours = 4
                let distance_start = 0
                let distance_end = next_funding_hours.distance
                let first_funding_settlement = (round(get_funding_arbitrages(distance_end,short_next_fundings,long_next_fundings))).toFixed(2)
                let first_funding_settlement_plus_entry_arb_percent = (parseFloat(first_funding_settlement)+parseFloat(entry_arb_percent)).toFixed(2)

                distance_start = distance_end
                distance_end = distance_start+range_hours
                let second_funding_settlement = (round(get_funding_arbitrages(distance_end,short_next_fundings,long_next_fundings))-
                                                 round(get_funding_arbitrages(distance_start,short_next_fundings,long_next_fundings))).toFixed(2)
                let second_sum_funding_settlement = (round(get_funding_arbitrages(distance_end,short_next_fundings,long_next_fundings))).toFixed(2)

                let second_sum_funding_settlement_plus_entry_arb_percent = (parseFloat(second_sum_funding_settlement)+parseFloat(entry_arb_percent)).toFixed(2)

                distance_start = distance_end
                distance_end = distance_start+range_hours
                let third_funding_settlement = (round(get_funding_arbitrages(distance_end,short_next_fundings,long_next_fundings))-
                                                round(get_funding_arbitrages(distance_start,short_next_fundings,long_next_fundings))).toFixed(2)
                let third_sum_funding_settlement = (round(get_funding_arbitrages(distance_end,short_next_fundings,long_next_fundings))).toFixed(2)

                let third_sum_funding_settlement_plus_entry_arb_percent = (parseFloat(third_funding_settlement)+parseFloat(entry_arb_percent)).toFixed(2)


                distance_start = distance_end
                distance_end = distance_start+range_hours
                let fourth_funding_settlement = (round(get_funding_arbitrages(distance_end,short_next_fundings,long_next_fundings))-
                                                round(get_funding_arbitrages(distance_start,short_next_fundings,long_next_fundings))).toFixed(2)                                                  
                let fourth_sum_funding_settlement = (round(get_funding_arbitrages(distance_end,short_next_fundings,long_next_fundings))).toFixed(2)     

                let fourth_sum_funding_settlement_plus_entry_arb_percent = (parseFloat(fourth_sum_funding_settlement)+parseFloat(entry_arb_percent)).toFixed(2)

                let arbitrage_4h = (round(get_funding_arbitrages(4,short_next_fundings,long_next_fundings))).toFixed(2)
                let arbitrage_8h = (round(get_funding_arbitrages(8,short_next_fundings,long_next_fundings))).toFixed(2)
                let arbitrage_12h = (round(get_funding_arbitrages(12,short_next_fundings,long_next_fundings))).toFixed(2)
                let arbitrage_16h = (round(get_funding_arbitrages(16,short_next_fundings,long_next_fundings))).toFixed(2)

                let funding_arbitrage = parseFloat(short_funding_rate-long_funding_rate).toFixed(4)
                let predicted_arbitrage = parseFloat(short_predicted_funding_rate-long_predicted_funding_rate).toFixed(4)

                if (rows<500)
                {
                    if ((check_range(entry_arb_percent,filters.entry) && 
                        check_range(first_funding_settlement,filters.firstSettlement) && 
                        check_range(second_sum_funding_settlement,filters.secondSettlement) &&
                        check_range(third_sum_funding_settlement,filters.thirdSettlement) &&
                        check_range(fourth_sum_funding_settlement,filters.fourthSettlement))
                        || filters_enabled == false )
                    {
                            rows++
                            data.push({
                            symbol: symbol_rows[long].source_symbol,
                            long_exchange: symbol_rows[long].source,
                            long_funding_rate: symbol_rows[long].funding_rate,
                            long_predicted_funding_rate: long_predicted_funding_rate,
                            long_last: symbol_rows[long].last,
                            short_exchange: symbol_rows[short].source,
                            short_funding_rate: symbol_rows[short].funding_rate,
                            short_predicted_funding_rate: short_predicted_funding_rate,
                            short_last: symbol_rows[short].last,
                            funding_arbitrage: funding_arbitrage,
                            predicted_arbitrage: predicted_arbitrage,
                            short_next_fundings: short_next_fundings,
                            long_next_fundings: long_next_fundings,
                            entry_arb_percent: entry_arb_percent,
                            arbitrage_4h: arbitrage_4h,
                            arbitrage_8h: arbitrage_8h,
                            arbitrage_12h: arbitrage_12h,
                            arbitrage_16h: arbitrage_16h,
                            first_funding_settlement: first_funding_settlement,
                            second_funding_settlement: second_funding_settlement,
                            third_funding_settlement: third_funding_settlement,
                            fourth_funding_settlement: fourth_funding_settlement,
                            first_sum_funding_settlement: first_funding_settlement,
                            second_sum_funding_settlement: second_sum_funding_settlement,
                            third_sum_funding_settlement: third_sum_funding_settlement,
                            fourth_sum_funding_settlement: fourth_sum_funding_settlement,
                            first_funding_settlement_plus_entry_arb_percent: first_funding_settlement_plus_entry_arb_percent,
                            second_sum_funding_settlement_plus_entry_arb_percent: second_sum_funding_settlement_plus_entry_arb_percent,
                            third_sum_funding_settlement_plus_entry_arb_percent: third_sum_funding_settlement_plus_entry_arb_percent,
                            fourth_sum_funding_settlement_plus_entry_arb_percent: fourth_sum_funding_settlement_plus_entry_arb_percent,
                            long_missing_predicted: long_missing_predicted,
                            short_missing_predicted: short_missing_predicted,
                            exchanges: symbol_rows.length
                        })
                    }
                }
            }
        }
    })
    return data
}

const check_range = (value,range) =>
{
    if (value>=parseFloat(range[0]) && value<=parseFloat(range[1])) return true
    else return false
}

export const round = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100
}

export function fill_investment_data(table_data,investment) {
    if (investment=='') investment = 1000;
    let table_data_with_investment = []
    table_data.forEach(row => {

        let long_last = parseFloat(row['long_last'])
        let short_last = parseFloat(row['short_last'])
        let long_funding_rate = parseFloat(row['long_funding_rate'])
        let short_funding_rate = parseFloat(row['short_funding_rate'])
        let long_predicted_funding_rate = parseFloat(row['long_predicted_funding_rate'])
        let short_predicted_funding_rate = parseFloat(row['short_predicted_funding_rate'])

        let quantity = round(investment/long_last)
        if (quantity>5) quantity=quantity.toFixed(0)
        if (quantity<5 && quantity>1) quantity=quantity.toFixed(1)
        if (quantity<1) quantity=quantity.toFixed(2)

        row['quantity'] = quantity
        let entry_cost_number = (short_last*quantity-long_last*quantity)
        let entry_cost = (entry_cost_number).toFixed(2)
        row['entry_cost'] = entry_cost
        table_data_with_investment.push(row)
        let fee=short_last*quantity*0.0004+long_last*quantity*0.0004
        row['fees'] = fee.toFixed(2)

        let long_funding_value = quantity*long_funding_rate*long_last/100
        let short_funding_value = quantity*short_funding_rate*short_last/100

        let long_predicted_value = quantity*long_predicted_funding_rate*long_last/100
        let short_predicted_value = quantity*short_predicted_funding_rate*short_last/100

        long_funding_value*=-1
        let funding_pnl = parseFloat(long_funding_value)+parseFloat(short_funding_value)
        row['funding_pnl'] = funding_pnl.toFixed(2)

        long_predicted_value*=-1
        let predicted_pnl = parseFloat(long_predicted_value)+parseFloat(short_predicted_value)
        row['predicted_pnl'] = predicted_pnl.toFixed(2)

        let pnl = parseFloat(funding_pnl+predicted_pnl-fee+entry_cost_number)
        row['pnl'] = pnl.toFixed(2)

        const date = new Date()
        let current_hour = date.getUTCHours()
        let next_funding_hours = get_next_four_funding_hours(current_hour)

        row['pnl4'] = get_nominal_value(row,4,quantity)
        row['pnl8'] = get_nominal_value(row,8,quantity)
        row['pnl12'] = get_nominal_value(row,12,quantity)
        row['pnl16'] = get_nominal_value(row,16,quantity)

        row['first_pnl'] = get_nominal_value(row,next_funding_hours.distance,quantity)
        row['second_pnl'] = get_nominal_value(row,next_funding_hours.distance+4,quantity)
        row['third_pnl'] = get_nominal_value(row,next_funding_hours.distance+8,quantity)
        row['fourth_pnl'] = get_nominal_value(row,next_funding_hours.distance+12,quantity)

    })
    return table_data_with_investment
}

const get_nominal_value = (row,hours,quantity) => {
    let short_funding_sum = get_single_funding_sum(hours,row.short_next_fundings)
    let long_funding_sum = get_single_funding_sum(hours,row.long_next_fundings)
    let long_last = parseFloat(row['long_last'])
    let short_last = parseFloat(row['short_last'])
    let long_funding_value = (quantity*long_funding_sum*long_last/100)*-1
    let short_funding_value = quantity*short_funding_sum*short_last/100
    let nominal = parseFloat(long_funding_value)+parseFloat(short_funding_value)
    return nominal.toFixed(2)
}

export function sanitize(full_data) {
    if (full_data === null) return
    let data = []
    full_data.forEach(row => {
        if (row.quantity!='Infinity' && row.quantity!='-Infinity' && row.entry_cost!='Infinity' && row.entry_cost!='-Infinity' &&
        row.entry_arb_percent!='Infinity' && row.entry_arb_percent!='-Infinity' ) data.push(row)
    })
    return data
}

export function extract_symbols(full_data)
{
    let symbols = []
    full_data.forEach(row => {
        if (!symbols.includes(row['symbol'])) symbols.push(row['symbol'])
    })
    return symbols
}

export function sort_by_symbol(full_data) {
    let symbols = extract_symbols(full_data.sort((a, b) => {
          return b.arbitrage_4h - a.arbitrage_4h }))
    if (full_data === null) return
    let data = []
    symbols.forEach(symbol => {
        full_data.forEach(row => {
            if (row['symbol']==symbol) data.push(row)
        })
    })
    return data
}

export function mobile_rows(full_data)
{
    let data = []
    let id=0
    full_data.forEach(row => {
        data.push({
            id: id++,
            symbol: row.symbol,
            last: row.long_last,
            f4: '',
            f8: '',
            f12:'',
            f16:'',            
            long_missing_predicted: row.long_missing_predicted,
            short_missing_predicted: row.short_missing_predicted,
        })
        data.push({
            id: id++,
            symbol: row.symbol,
            last: row.short_last,
            f4: '',
            f8: '',
            f12:'',
            f16:'',
            long_missing_predicted: row.long_missing_predicted,
            short_missing_predicted: row.short_missing_predicted,
        })
        data.push({
            id: id++,
            symbol: '',
            last: row.entry_arb_percent,
            f4: row.arbitrage_4h,
            f8: row.arbitrage_8h,
            f12: row.arbitrage_12h,
            f16: row.arbitrage_16h,
            long_missing_predicted: row.long_missing_predicted,
            short_missing_predicted: row.short_missing_predicted,
        })
        data.push({
            id: id++,
            symbol: '',
            last: row.entry_cost,
            f4: row.pnl4,
            f8: row.pnl8,
            f12: row.pnl12,
            f16: row.pnl16,
            long_missing_predicted: row.long_missing_predicted,
            short_missing_predicted: row.short_missing_predicted,
        })
        data.push({
            id: id++,
            symbol: '',
            last: '',
            f4: '',
            f8: '',
            f12:'',
            f16:'',
            long_missing_predicted: row.long_missing_predicted,
            short_missing_predicted: row.short_missing_predicted,
        })
    })
    return data
}


export function fill_last_average(table_data) {
    let exchanges_average_last = ['BINANCE','OKX']
    let average_last = []
    let table_data_with_average = []

    table_data.forEach(row => {
        if (exchanges_average_last.includes(row.source)) average_last.push(row.last)
    })
    const sum = average_last.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    const avg = (sum / average_last.length) || 0

    table_data.forEach(row => {
        let spread = parseFloat(row.last)-avg
        if (spread < -10 || spread > 10) spread = spread.toFixed(0)
        else spread = spread.toFixed(1)
        row['spread'] = spread
        table_data_with_average.push(row)
    })

    return { table_data_with_average: table_data_with_average, average : avg }
}

export function get_average(table_data_with_average) {
    let average = 0
    table_data_with_average.forEach(row => {
        if (Object.prototype.hasOwnProperty.call(row,'last') && 
            Object.prototype.hasOwnProperty.call(row,'spread') ){
                average = parseFloat(row.last) + parseFloat(row.spread)
        }
    })
    return average
}


export function get_spread(data) {
    let maximum_value = 0
    let maximum_source = ''
    let minimum_value = 1000
    let minimum_source = ''

    data.forEach(row => {
        if (row.funding_rate !== undefined && row.funding_rate !== '') {
            if (row.funding_rate === '') row.funding_rate = 0
            if (row.funding_rate > maximum_value) {
                maximum_value = row.funding_rate
                maximum_source = row.source
            }
            if (row.funding_rate < minimum_value) {
                minimum_source = row.source
                minimum_value = row.funding_rate
            }
        }
    })

    return {
        spread: (maximum_value - minimum_value).toFixed(4),
        maximum_source: maximum_source,
        minimum_source: minimum_source,
        maximum_value: maximum_value,
        minimum_value: minimum_value
    }
}




export function decompress_with_header(lastMessage, callback) {
    if (lastMessage === undefined) return undefined
    lastMessage.data.arrayBuffer().then(input_array => {
        const compressed = toUint8Array(input_array)
        try {
            const decompressed = pako.inflate(compressed, { 'to': 'string' })
            let jsonMessage = JSON.parse(decompressed)
            callback(jsonMessage)
        }
        catch (err) {
            ConsoleHelper(err)
        }
    }
    )
}

export function decompress_without_header(lastMessage, callback) {
    if (lastMessage === undefined) return undefined
    lastMessage.data.arrayBuffer().then(input_array => {
        const compressed = toUint8Array(input_array)
        try {
            const decompressed = pako.inflateRaw(compressed, { 'to': 'string' })
            let jsonMessage = JSON.parse(decompressed)
            callback(jsonMessage)
        }
        catch (err) {
            ConsoleHelper(err)
        }
    }
    )
}




export function toUint8Array(src) {
    var dst = new ArrayBuffer(src.byteLength)
    new Uint8Array(dst).set(new Uint8Array(src))
    return dst
}
