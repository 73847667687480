import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import theme_json from './theme.json'
import walletTRON from './res/walletTRON.png'
import walletBSC from './res/walletBSC.png'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material/styles'
import './wdyr'

import App from './App'
import Config from './Config'
import Realtime from './Realtime'
import Futures from './Futures'
import Exchanges from './Exchanges'
import Funding from './Funding'
import Opportunities from './Opportunities'
import SuperOpportunities from './SuperOpportunities'
import Strategy from './components/Strategy'
import Positions from './Positions'
import Ups from './Ups'

import SocketManager from './sources/SocketManager'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from './context/ConfigProvider'
import { RealtimeDataProvider } from './context/RealtimeDataProvider'
import { FundingDataProvider } from './context/FundingDataProvider'
import { FuturesDataProvider } from './context/FuturesDataProvider'
import { UPSDataProvider } from './context/UPSDataProvider'

import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'

//import { createMuiTheme, ThemeProvider } from '@mui/core/styles';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

/*
const url_prefix = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return ''
  } else {
      return ''
      //return '/cripto-info'
  }
}
*/

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
        <RealtimeDataProvider>
          <FundingDataProvider>
            <FuturesDataProvider>
               <Router> 
               <UPSDataProvider>
               <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={createTheme(theme_json)}>
                  <ThemeProvider theme={createTheme(theme_json)}>
                  <AppBar position='fixed'>
                    <Toolbar variant='dense'>
                      <Button color='inherit' to={'/'} component={Link}>Home</Button>
                      <Button color='inherit' to={'/funding'} component={Link}>Funding</Button>
                      <Button color='inherit' to={'/realtime'} component={Link}>Realtime</Button>
                      <Button color='inherit' to={'/futures'} component={Link}>Futures</Button>
                      <Button color='inherit' to={'/exchanges'} component={Link}>Exchanges</Button>
                      <Button color='inherit' to={'/positions'} component={Link}>Positions</Button>
                      <Button color='inherit' to={'/config'} component={Link}>Config</Button>
                      <div style={{flexGrow: '1'}}></div>
                      <Typography variant='caption'><Ups /></Typography>
                    </Toolbar>
                  </AppBar>
                  <Switch>
                    <Route path={'/config'}>
                      <Config />
                    </Route>
                    <Route path={'/funding'}>
                      <Funding />
                    </Route>
                    <Route path={'/opportunities'}>
                      <Opportunities />
                    </Route>
                    <Route path={'/super_opportunities'}>
                      <SuperOpportunities />
                    </Route>
                    <Route path={'/realtime'}>
                      <Realtime />
                    </Route>
                    <Route path={'/futures'}>
                      <Futures />
                    </Route>
                    <Route path={'/exchanges/:exchange?'}>
                      <Exchanges />
                    </Route>
                    <Route path={'/positions'}>
                      <Positions />
                    </Route>
                    <Route path={'/strategy/:symbol?/:long_exchange/:short_exchange?'}>
                      <Strategy />
                    </Route>
                    <Route exact path={'/:light?'}>
                      <App />
                    </Route>
                  </Switch>
                    </ThemeProvider>
                  </MuiThemeProvider>
                  </StyledEngineProvider>
                  <SocketManager/>
                </UPSDataProvider>
              </Router>   
            </FuturesDataProvider>
          </FundingDataProvider>
        </RealtimeDataProvider>
          <center>
            <span className='donations'>We accept donations to help support the website</span>
            <br/>
            <span className='donations'>Aceptamos donaciones para ayudar a mantener el sitio web</span>
            <br/>
            <br/>
            <br/>
            <table border='0' cellPadding='5' cellSpacing='5'>
              <tbody>
              <tr>
                <td align='center' valign='top' width='50%'>
                    <img src={walletTRON} width='50' alt='Tron Wallet' />
                    <br/>
                    <span className='donations'>USDT or TRX</span>
                    <br/>
                    <span className='donations'>TRON Network (TRC20)</span>
                    <br/>
                    <span className='donations'>TPi5m9GVrnD52AECtxUnCoKTZFCC8MofVt</span>
                </td>
                <td align='center' valign='top' width='50%'>
                    <img src={walletBSC} width='50' alt='BSC Wallet' />
                    <br/>
                    <span className='donations'>USDT, ETH, BTC, BCH or BNB</span>
                    <br/>
                    <span className='donations'>Binance Smart Chain (BEP20)</span>
                    <br/>
                    <span className='donations'>0x796825c2e169b50142004347b4237b5a84a00d75</span>
                </td>
              </tr>
              </tbody>
            </table>

            <br/><br/><br/><br/>
          </center>
      </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
