import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import ConsoleHelper from '../tools/ConsoleHelper'

export const SOURCE = 'PHEMEX'

const COIN = 0
const USDT = 1
const EUR = 2
const BUSD = 3

const PERPETUAL = 0
const FUTURES = 1

/*
General Public API Information
COIN:
https://github.com/phemex/phemex-api-docs/blob/master/Public-Contract-API-en.md#general
USDT:
https://github.com/phemex/phemex-api-docs/blob/master/Public-Hedged-Perpetual-API.md#general
*/

export function Phemex(symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {

        const connect = () => {
            let ws = new ZSocket('wss://ws10.phemex.com/', NO_COMPRESSION); webSocket.current = ws
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                //ws.sendJson({'method': 'market24h.subscribe','params': [],'id': 1234})
                symbols.forEach (symbol => {
                    switch (symbol.type)
                    {
                        case COIN: {
                            ws.sendJson({ method: 'trade.subscribe', params: [`${symbol.source_symbol.symbol}`], id: 1}); 
                            ws.sendJson({ method: 'tick.subscribe', params: [`.${symbol.source_symbol.symbol}`], id: 2}); 
                            ws.sendJson({ method: 'tick.subscribe', params: [`.${symbol.source_symbol.symbol}FR8H`], id: 3}); // Funding Rate
                            ws.sendJson({ method: 'tick.subscribe', params: [`.${symbol.source_symbol.symbol}FR`], id: 4}); // Predicted
                        }
                        break;
                        case USDT: {
                            ws.sendJson({ method: 'trade_p.subscribe', params: [`${symbol.source_symbol.symbol}USDT`], id: 1}); 
                            ws.sendJson({ method: 'tick_p.subscribe', params: [`.${symbol.source_symbol.symbol}USDT`], id: 2}); 
                            ws.sendJson({ method: 'tick_p.subscribe', params: [`.${symbol.source_symbol.symbol}USDTFR8H`], id: 3}); // Funding Rate
                            ws.sendJson({ method: 'tick_p.subscribe', params: [`.${symbol.source_symbol.symbol}USDTFR`], id: 4}); // Predicted
                        } 
                        break;
                    }
                })

                ping_interval.current = setInterval(() => {
                    if (ws?.sendJson!==undefined) ws.sendJson({'id': 1000,'method': 'server.ping','params': []})
                }, 20000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (jsonMessage) => {

                /*
                if (jsonMessage?.market24h!==undefined)
                {
                    let symbol_data = jsonMessage.market24h
                    symbols.forEach (symbol => {
                        if (symbol_data.symbol.toUpperCase()===symbol.symbol.toUpperCase())
                        {
                            update_internal(symbols,symbol_data.symbol,{
                                last: parse(symbol_data.markPrice/10000),
                                funding_rate: parse100(symbol_data.fundingRate/100000000),
                                predicted_funding_rate: parse100(symbol_data.predFundingRate/100000000)
                            })
                        }
                    })
                }
                */
                if (jsonMessage?.tick!==undefined) {
                    let tick = jsonMessage.tick
                    symbols.forEach (symbol => {
                        /*
                        if (tick.symbol==="."+symbol.source_symbol.symbol) {
                            switch (tick.scale)
                            {
                                case 4: update_internal(symbols,symbol.source_symbol.symbol+"USD",{last: parse(tick.last/10000)}); break;
                                case 6: update_internal(symbols,symbol.source_symbol.symbol+"USD",{last: parse(tick.last/100000000)}); break;
                                case 8: update_internal(symbols,symbol.source_symbol.symbol+"USD",{last: parse(tick.last/100000000)}); break;
                            }
                        }
                        else */
                        if (tick.symbol==="."+symbol.source_symbol.symbol+"FR8H") { // Funding Rate
                            switch (tick.scale)
                            {
                                case 4: update_internal(symbols,symbol.source_symbol.symbol+"USD",{predicted_funding_rate: parse100(tick.last/10000)}); break;
                                case 6: update_internal(symbols,symbol.source_symbol.symbol+"USD",{predicted_funding_rate: parse100(tick.last/100000000)}); break;
                                case 8: update_internal(symbols,symbol.source_symbol.symbol+"USD",{predicted_funding_rate: parse100(tick.last/100000000)}); break;
                            }
                        }
                        else if (tick.symbol==="."+symbol.source_symbol.symbol+"FR") { // Predicted
                            switch (tick.scale)
                            {
                                case 4: update_internal(symbols,symbol.source_symbol.symbol+"USD",{funding_rate: parse100(tick.last/10000)}); break;
                                case 6: update_internal(symbols,symbol.source_symbol.symbol+"USD",{funding_rate: parse100(tick.last/100000000)}); break;
                                case 8: update_internal(symbols,symbol.source_symbol.symbol+"USD",{funding_rate: parse100(tick.last/100000000)}); break;
                            }
                        }
                    })

                }
                else if (jsonMessage?.tick_p!==undefined) {
                    let tick_p = jsonMessage.tick_p
                    symbols.forEach (symbol => {
                        /*
                        if (tick_p.symbol==="."+symbol.source_symbol.symbol+"USDT") {
                            update_internal(symbols,symbol.source_symbol.symbol+"USDT",{last: parse(tick_p.last)})
                        }
                        else*/ 
                        if (tick_p.symbol==="."+symbol.source_symbol.symbol+"USDTFR8H") { // Funding Rate
                            update_internal(symbols,symbol.source_symbol.symbol+"USDT",{predicted_funding_rate: parse100(tick_p.last)})
                        }
                        else if (tick_p.symbol==="."+symbol.source_symbol.symbol+"USDTFR") { // Predicted
                            update_internal(symbols,symbol.source_symbol.symbol+"USDT",{funding_rate: parse100(tick_p.last)})
                        }
                    })
                }
                else if (jsonMessage?.trades!==undefined) {
                    let trades = jsonMessage.trades
                    let last = trades[0][2]
                    symbols.forEach (symbol => {
                        if (jsonMessage.symbol===symbol.source_symbol.symbol+"USDT") {
                            update_internal(symbols,symbol.source_symbol.symbol+"USDT",{last: parse(last)})
                        }
                    })
                }
                else if (jsonMessage?.trades_p!==undefined) {
                    let trades_p = jsonMessage.trades_p
                    if (trades_p!=undefined)
                    {
                        if (trades_p[0]!=undefined)
                        {
                            if (trades_p[0][2]!=undefined)
                            {
                                let last = trades_p[0][2]
                                symbols.forEach (symbol => {
                                    if (jsonMessage.symbol===symbol.source_symbol.symbol+"USDT") {
                                        update_internal(symbols,symbol.source_symbol.symbol+"USDT",{last: parse(last)})
                                    }
                                })
                            }
                        }

                    }

                }
                else if (jsonMessage?.result?.status==='success') { /* Success */ }
                else if (jsonMessage?.result==='pong') { /* Ping */ }
                else
                {
                    //ConsoleHelper(SOURCE,jsonMessage)
                }
                
            }
        }
        connect()

        return () => {
            //ws.close()
        }

    }, [symbols,update_internal,parse,parse100])

    return webSocket.current
}

