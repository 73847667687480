import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import {Table} from './Table'
import { FundingContext } from '../context/FundingDataProvider'
import EventEmitter from '../tools/Events'

export default function FundingTable({title, symbol, types})
{ 
    const { fundingData } = useContext(FundingContext)

    const columns = [
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'Source',
            accessor: 'source',
          },
          {
            Header: 'Symbol',
            accessor: 'symbol',
          },
          {
            Header: '',
            accessor: 'type_icon',
          },
          {
            Header: 'Last',
            accessor: 'last',
            sortType: 'basic'
          },
          {
            Header: ' Funding',
            accessor: 'funding_rate',
            Cell: ({ value }) => {
                return tools.format_funding(value)
            }
          },
          {
            Header: ' Predicted',
            className: 'fixed',
            accessor: 'predicted_funding_rate',
            Cell: ({ value }) => {
                return tools.format_funding(value)
            }
          },
          {
            Header: 'Spread',
            accessor: 'spread',
          },
          {
            Header: 'Mod',
            accessor: 'modified',
          },
          {
            Header: 'Ref',
            accessor: 'refresh',
          },
          {
            Header: 'WS',
            accessor: 'status',
            className: 'fixed-width',
            Cell:   ({row,value}) => {
              return (
                <span onClick={() => {
                EventEmitter.emit('webSocketClick',row.values)
                }
                }>{value}</span>
              )
            }

          }
    ]
    
    

    let table_data = tools.symbol_data_funding(fundingData,symbol,types)

    let fill_last_average_result = tools.fill_last_average(table_data)
    let table_data_with_average = fill_last_average_result.table_data_with_average
    let average = fill_last_average_result.average

    /*
    const Spread = (props) => {
        let spread = tools.get_spread (props.data);
        return <>
                <span className='spread text'>Spread: </span>
                <span className='spread value'>{(spread.spread)}</span> 
                <span className='spread text'> from </span>  
                <span className='spread symbol'>{(spread.maximum_source)}</span> 
                <span className='spread value'> ({(spread.maximum_value)})</span> 
                <span className='spread text'> to </span>  
                <span className='spread symbol'>{(spread.minimum_source)}</span> 
                <span className='spread value'> ({(spread.minimum_value)})</span> 
               </>; 
    }
    */

    return (
        <>
            <center><span className='spread text'>{title ? title : symbol} {tools.dynamic_decimals(average)}</span></center>
            {/* <center><Spread data={table_data}></Spread></center> */}
            <Table columns={columns} data={table_data_with_average}/>
        </>
    )

}



//FundingTable.whyDidYouRender = true;