import { useContext } from 'react'
import 'fontsource-roboto'
import './App.css'
import axios from 'axios'
import Button from '@mui/material/Button';
import { useLocation } from "react-router-dom";

//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/dist/js/bootstrap.bundle.min'

//import { withTheme } from '@rjsf/core'
//import Theme from '@rjsf/material-ui/v5'
//import Form from '@rjsf/mui';

import Form from '@rjsf/mui'
import validator from "@rjsf/validator-ajv8";
import { titleId } from '@rjsf/utils';

import Grid from '@mui/material/Grid'
import { ConfigContext, exchanges } from './context/ConfigProvider'
import { useHistory } from 'react-router-dom'

//const Form = withTheme(Theme)

const url_prefix = () => {
  if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === 'development') {
      return ''
  } else {
      return ''
  }
}

function ArrayFieldTitleTemplate(props) {
  const { description, idSchema } = props;
  const id = titleId(idSchema);
  return <h1 id={id}>{title}</h1>;
}

function Config() {
  const  { config, setConfig, Styles } = useContext(ConfigContext)
  const exchange_list = exchanges.map(exchange => exchange.text);

  const query = new URLSearchParams(useLocation().search)
  let unblock = false
  unblock = query.get("unblock")
  
  const schema = {
      type: 'object',
      properties: {
        symbols: {
          title: 'Symbol List',
          type: 'array',
          items: {
            type: 'object',
            title: '',
            properties: {
              symbol: {
                title: 'Symbol Ticker',
                type: 'string'
              },
              highlight_funding: {
                title: 'Highlight Funding',
                type: 'number',
                minimum: 0,
                maximum: 1,
                multipleOf: 0.01,
              },
              highlight_predicted_funding: {
                title: 'Highlight Predicted Funding',
                type: 'number',
                minimum: 0,
                maximum: 1,
                multipleOf: 0.01,
              }
            }
          }
        },
        accounts: {
          title: 'Accounts',
          type: 'array',
          items: {
            type: 'object',
            title: '',
            properties: {
              exchange:{
                type: 'string',
                title: 'Exchange',
                enum: ['BINANCE', 'BITMEX', 'BYBIT', 'DERIBIT', 'FTX','HTX','KUCOIN','OKX']
              },
              account_name: {
                title: 'Acount name (optional)',
                type: 'string'
              },
              api_key: {
                title: 'API Key',
                type: 'string'
              },
              api_secret: {
                title: 'API Secret',
                type: 'string'
              }
            }
          }
        },
        positions: {
          title: 'Positions',
          type: 'array',
          items: {
            type: 'object',
            title: '',
            properties: {
              symbol: {
                title: 'Symbol',
                type: 'string'
              },
              quantity: {
                title: 'Quantity',
                type: 'number',
                minimum: 0,
                maximum: 100000000,
                multipleOf: 0.01,
              },
              long_exchange:{
                type: 'string',
                title: 'Long',
                enum: exchange_list
              },
              long_entry: {
                title: 'Entry',
                type: 'number',
                minimum: 0,
                maximum: 100000000,
                multipleOf: 0.00001,
              },
              long_liquidation: {
                title: 'Liquidation',
                type: 'number',
                minimum: 0,
                maximum: 100000000,
                multipleOf: 0.00001,
              },
              short_exchange:{
                type: 'string',
                title: 'Short',
                enum: exchange_list
              },
              short_entry: {
                title: 'Entry',
                type: 'number',
                minimum: 0,
                maximum: 100000000,
                multipleOf: 0.00001,
              },
              short_liquidation: {
                title: 'Liquidation',
                type: 'number',
                minimum: 0,
                maximum: 100000000,
                multipleOf: 0.00001,
              }
            }
          }
        }
      }
    }

  const history = useHistory()

  const onSubmit = ({formData}) => {
    setConfig(formData)
    let prefix = url_prefix()
    history.push(`${prefix}/`)
    window.location.reload()
  }
 
  const uiSchema = {
    items: {
      'ui:title': '',
      "ui:options": {label: false}
    }
  }

  const handleClick = () => {
    axios.get('https://www.fundingarb.com.ar/hot_symbols')
    .then(res => {
      let symbols = []
      res.data.forEach(symbol => {
          symbols.push({
            symbol: symbol,
            highlight_funding: 0.10,
            highlight_predicted_funding: 0.10
          })
      })
      setConfig({symbols: symbols})
      let prefix = url_prefix()
      history.push(`${prefix}/`)
      window.location.reload()
    })
  }

  let button = ''
  if (unblock) button = <Button variant="contained" onClick={handleClick}>Load Hot Symbols</Button> 

  return (
    <Styles>
        <br></br><br></br><br></br>
        <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
        style={{ minHeight: '100vh' }}
        >

          <Grid item xs={11}>
              <Form schema={schema}
                    uiSchema={uiSchema}
                    formData={config}
                    onSubmit={onSubmit}
                    liveValidate={true}
                    validator={validator}
              />
          </Grid>

        </Grid>
        <br></br>
        {button}
        
    </Styles>
  )
  
}

Config.whyDidYouRender = true

export default Config
