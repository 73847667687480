import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { FUTURES } from '../context/ConfigProvider'
import axios from 'axios'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'
import { ZSocket, NO_COMPRESSION } from './ZSocket'

export const SOURCE = 'DERIBIT'

export function Deribit(symbols,update_internal,update_connection){

    const ws_url = 'wss://www.deribit.com/ws/api/v2/'
    const z_socket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {
        symbols.forEach (symbol => {
            if (symbol.contract === FUTURES) {
                get_expiry({url: `https://www.deribit.com/api/v2/public/get_instrument?instrument_name=${symbol.symbol}`,symbols:symbols,update_internal:update_internal})
            }
        })

        const connect = () => {
            let ws = new ZSocket(ws_url, NO_COMPRESSION); z_socket.current = ws
    
            z_socket.current.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    z_socket.current.sendJson({
                        method: 'public/subscribe',
                        params: {
                        channels: [
                            `ticker.${symbol.symbol}.100ms`
                        ]
                        },
                        jsonrpc: '2.0',
                        id: 0
                    })
                })

                ping_interval.current = setInterval(() => {
                    let ping_message = {action: '/api/v1/public/ping'}
                    z_socket.current.sendJson(ping_message)
                }, 30000)
            }

            z_socket.current.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            z_socket.current.jsonReceived = (json_message) => {
                try {
                    if (json_message?.params!==undefined)
                    update_internal(symbols,json_message.params.data.instrument_name,{
                        funding_rate: parse100(json_message.params.data.current_funding),
                        last: parse(json_message.params.data.last_price),
                        predicted_funding_rate: parse100(json_message.params.data.funding_8h),
                    })
                } catch(e) {
                    ConsoleHelper(e,json_message)
                }
            }

            z_socket.current.messageReceived = (message) => {
                ConsoleHelper(SOURCE,message)
            }
        }
        connect()

        return () => {
            //webSocket.current.close()
            //clearInterval(ping_interval.current)
        }

    }, [symbols,update_internal,parse,parse100])

    return z_socket.current
}

const get_expiry = ({url,symbols,update_internal}) => {
    axios.get(url)
    .then(res => {
      const data = res.data
      update_internal (symbols, data.result.instrument_name,{expiry: new Date(data.result.expiration_timestamp)})
    })
}
