import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import {Table} from './Table'
import { FundingContext } from '../context/FundingDataProvider'
import EventEmitter from '../tools/Events'

export default function RealtimeTable ({title, symbol, types})
{  
    const columns = React.useMemo(
      () => [
        {
          Header: 'ID',
          accessor: 'id', // accessor is the 'key' in the data
        },
        {
          Header: 'Source',
          accessor: 'source',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color) {
              return (
              <div style={{ background: row.original.highlight_color,  color: '#FFF'}} 
              onClick={() => {EventEmitter.emit('highlightRow',row.values)}}
              >{value}</div>
              )
            }
            return (
              <div onClick={() => {EventEmitter.emit('highlightRow',row.values)}}
              >{value}</div>
            )
          }
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color,  color: '#FFF' }} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: '',
          accessor: 'type_icon',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color ,  color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'Last',
          accessor: 'last',
          sortType: 'basic',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color, color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        },
        {
          Header: 'WS',
          accessor: 'status',
          className: 'fixed-width',
          Cell:   ({row,value}) => {
            if (row.original.highlight_color)
                return (<div style={{ background: row.original.highlight_color ,  color: '#FFF'}} >{value}</div>)
            return (<div>{value}</div>)
          }
        }
      ],
      []
    ) 
    
    const { fundingData } = useContext(FundingContext)
    let table_data = tools.symbol_data_realtime(fundingData,symbol,types)
    
    return (
      <>
        <center><span className='spread text'>{title ? title : symbol}</span></center>
        <Table titulo={title ? title : symbol} columns={columns} data={table_data} />
      </>
    )

}

//RealtimeTable.whyDidYouRender = true;
