import React, { useContext } from 'react'
import 'fontsource-roboto'
import './App.css'
import { UPSContext } from './context/UPSDataProvider'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

function Ups() {

  const { updateRate } = useContext(UPSContext)

  let sum = 0
  let tooltip = ''
  for (const exchange of updateRate) {      
    sum+=exchange.ups
    tooltip=tooltip+exchange.source+': <b>'+exchange.ups+'</b><br>'
  }

  const getFragment = (updateRate) => {
    let tooltip = ''

    for (const exchange of updateRate.sort((a,b) => b.ups - a.ups)) {      
      tooltip=tooltip+exchange.source+': <b>'+exchange.ups+'</b><br>'
    }

    return (
    <React.Fragment>
    <Typography color="inherit">Last second:</Typography>
      <div style={{justifyContent:'right'}} dangerouslySetInnerHTML={{__html: tooltip}} />
    </React.Fragment>
    )
  }



  return ( 
    <Tooltip title={getFragment(updateRate)} >
      <span>UPS: { sum }</span>
    </Tooltip>
  )
  
}

export default Ups