import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import ConsoleHelper from '../tools/ConsoleHelper'

export const SOURCE = 'COINEX'

/*
API Information
https://github.com/coinexcom/coinex_perpetual_api/blob/master/perpetual_websocket_api.md
*/

export function Coinex(symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {

        const connect = () => {
            let ws = new ZSocket('wss://perpetual.coinex.com', NO_COMPRESSION); webSocket.current = ws

            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    ws.sendJson(
                        {
                            'method':'state.subscribe',
                            'params':[symbol.symbol],
                            'id':15
                        }          
                    )
                })

                ping_interval.current = setInterval(() => {
                    ws.sendJson({
                        'method':'server.ping',
                        'params':[],
                        'id': 11
                    })
                }, 20000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (jsonMessage) => {
                if (jsonMessage?.method === 'state.update')
                {
                    let symbol = Object.keys(jsonMessage.params[0])[0]
                    let symbol_data = jsonMessage.params[0][symbol]
                    if (symbol_data !== undefined) {
                        update_internal(symbols,symbol,{
                            last: parse(symbol_data.last),
                            funding_rate: parse100(symbol_data.funding_rate_next),
                            predicted_funding_rate: parse100(symbol_data.funding_rate_predict)
                        })
                    }
                }
                else if (jsonMessage?.result === 'pong') {
                    // Do Nothing
                }
                else if (jsonMessage?.result?.status === 'success') {
                    // Do Nothing
                }
                else
                {
                    ConsoleHelper(SOURCE,jsonMessage)
                }
            }
        }
        connect()

        return () => {
            //ws.close()
        }

    }, [symbols,update_internal,parse,parse100])

    return webSocket.current
}

