import React, { useContext } from 'react'
import 'fontsource-roboto'
import './App.css'
import { ConfigContext, COIN,USDT,EUR,BUSD } from './context/ConfigProvider'
import FundingTable from './tables/FundingTable'
import SlowRealtimeTable from './tables/SlowRealtimeTable'
import FuturesTable from './tables/FuturesTable'
import {Status} from './components/Status';
import { UPSContext } from './context/UPSDataProvider'

function App() {

  const  { config, Styles } = useContext(ConfigContext)
  const { updateRate } = useContext(UPSContext)

   // const query = new URLSearchParams(useLocation().search);
   // let light_query = query.get("light")
   // if (light_query) setLight(true)

  return (
    <Styles>
      <br></br>
      <center>
      <Status exchanges={updateRate} ></Status>
      </center>
      <br></br><br></br><br></br>
      <FundingTable title="BTC EUR" symbol="BTC" types={[EUR]} />
      {
        config.symbols.map((symbol) => (
          <React.Fragment key={symbol.symbol}>
            <br></br>
            <FundingTable title={symbol.symbol+'-C'} symbol={symbol.symbol} types={[COIN]} />
            <FundingTable title={symbol.symbol+'-T'} symbol={symbol.symbol} types={[USDT,BUSD]} />
            <br></br>
          </React.Fragment>
        ))
      }
      <br></br>
      <FuturesTable title="Futures Bitcoin" symbol="BTC" less_than_days="40" types={[COIN,USDT]} />
      <br></br>
      <FuturesTable symbol="BTC" greater_than_days="39" types={[COIN,USDT]}/>
      <br></br>
      <br></br>
      <FuturesTable title="Futures Ethereum" symbol="ETH" less_than_days="40" types={[COIN,USDT]}/>
      <br></br>
      <FuturesTable symbol="ETH" greater_than_days="39" types={[COIN,USDT]}/>
      <br></br>
      <br></br>
      <br></br>
      <SlowRealtimeTable title="Slowed Realtime Bitcoin EUR" symbol="BTC" types={[EUR]} />
      <SlowRealtimeTable title="Slowed Realtime Bitcoin" symbol="BTC" types={[COIN,USDT,BUSD]} />
      <br></br>
      <br></br>
      <SlowRealtimeTable title="Slowed Realtime Ethereum" symbol="ETH" types={[COIN,USDT,BUSD]} />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Styles>
  )
  
}

App.whyDidYouRender = true
export default App
