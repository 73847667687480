import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { ZSocket, INFLATE } from './ZSocket'
import axios from 'axios'
import ConsoleHelper from '../tools/ConsoleHelper'

export const MARKET_DATA_REQUEST = 0
export const ORDER_PUSH = 1
export const INDEX_KLINE_DATA = 2

export const SOURCE = 'HTX'

/*
USDT
Market Data Request and Subscription: wss://api.hbdm.com/linear-swap-ws
Order Push Subscription: wss://api.hbdm.com/linear-swap-notification
Index Kline Data and Basis Data Subscription: wss://api.hbdm.com/ws_index

COIN
Market Data Request and Subscription: wss://api.hbdm.com/swap-ws
Order Push Subscription: wss://api.hbdm.com/swap-notification
Index Kline Data and Basis Data Subscription: wss://api.hbdm.com/ws_index
*/

export function Htx(ws_url,symbols,address,update_internal,update_connection){

    const webSocket = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {

        const connect = () => {
            let ws = new ZSocket(ws_url, INFLATE); webSocket.current = ws
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    let jsonMessage
                    if (address === ORDER_PUSH)  jsonMessage = {op: 'sub', cid: '50sG903yz80oDFWx', topic: `public.${symbol.symbol}.funding_rate`}
                    if (address === MARKET_DATA_REQUEST) jsonMessage = { sub: `market.${symbol.symbol}.kline.1min`, id: `id${symbol.id}` }
                    ws.sendJson(jsonMessage)
                })
            }
            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }
            ws.jsonReceived = (jsonMessage) => {
                if (Object.prototype.hasOwnProperty.call(jsonMessage,'ping')){
                    ws.sendJson({pong: jsonMessage.ping})
                }
                else if (Object.prototype.hasOwnProperty.call(jsonMessage,'op'))
                {
                    if (jsonMessage.op === 'ping'){
                        ws.sendJson({op: 'pong', ts: jsonMessage.ts})
                    }
                    else
                    if (jsonMessage.op === 'notify')
                    {
                    if (Object.prototype.hasOwnProperty.call(jsonMessage,'data'))
                    {
                        update_internal(symbols,jsonMessage.data[0].contract_code,{
                            funding_rate: parse100(jsonMessage.data[0].funding_rate),
                            predicted_funding_rate: parse100(jsonMessage.data[0].estimated_rate)
                        })
                    }
                    }
                }
                else if (Object.prototype.hasOwnProperty.call(jsonMessage,'tick'))
                {
                    let contract = jsonMessage.ch.split('.')[1]
                    update_internal(symbols,contract,{
                        last: parse(jsonMessage.tick.close)
                    })
                }
                else if (jsonMessage?.status === 'ok') { /* Subscription made */
                }
                else
                {
                    //ConsoleHelper({SOURCE,jsonMessage})
                }
            }
        }
        connect()

        return () => {
            //ws.close()
        }
    }, [ws_url,symbols,address,update_internal,parse,parse100])

    return webSocket.current
}

// 

export const get_expiry = ({symbols,update_internal}) => {
    axios.get('https://www.fundingarb.com.ar/huobi_futures')
    .then(res => {
      const data = res.data
      data.data.forEach (data_symbol => {
        symbols.forEach (symbol => {
        if (data_symbol.contract_code === symbol.symbol)
        {
            let expiry = new Date(parseInt(data_symbol.delivery_time))
            update_internal(symbols,symbol.symbol,{
                expiry: expiry
            })
        }
        })
     })

    }).catch(err => {
        ConsoleHelper({SOURCE,err})
    })

}
