import React, { useState, useEffect } from 'react'
import default_config from '../default_config.json'
import futures_json from '../futures.json'
import * as tools from '../tools/tools'
import styled from 'styled-components'
import * as BITMEX from '../sources/bitmex'
import * as BINANCE from '../sources/binance'
import * as BYBIT from '../sources/bybit'
import * as OKX from '../sources/okx'
import * as HTX from '../sources/htx'
import * as FTX from '../sources/ftx'
import * as DERIBIT from '../sources/deribit'
import * as KUCOIN from '../sources/kucoin'
import * as POLONIEX from '../sources/poloniex'
import * as PHEMEX from '../sources/phemex'
import * as ASCENDEX from '../sources/ascendex'
//import * as DELTA_EXCHANGE from '../sources/delta'
import * as GATE_IO from '../sources/gate_io'
import * as BITGET from '../sources/bitget'
import * as COINEX from '../sources/coinex'
import * as KRAKEN from '../sources/kraken'
import * as BINGX from '../sources/bingx'
import * as MEXC from '../sources/mexc'
import * as DYDX from '../sources/dydx'
import * as HYPE from '../sources/hyperliquid'

import useLocalStorage from '../tools/useLocalStorage'
import { MdHourglassEmpty } from 'react-icons/md'
import { Icon } from '@iconify/react'
import tetherIcon from '@iconify/icons-cryptocurrency/usdt'
import binanceIcon from '@iconify/icons-cryptocurrency/bnb'
import { BiDollarCircle } from 'react-icons/bi'
 
import {Mutex} from 'async-mutex'

export const ConfigContext = React.createContext()

export const COIN = 0
export const USDT = 1
export const EUR = 2
export const BUSD = 3

export const PERPETUAL = 0
export const FUTURES = 1



export const mutex = new Mutex()

const Styles = styled.div`
@charset 'UTF-8';

body {
font-family: 'monospace', monospace;
font-weight: 400;
line-height: 1.42em;
color:#A7A1AE;
background-color:#1F2739;
}

.fixed {
font-family: 'Lucida Console', Courier, monospace;
}

h1 {
font-size:3em; 
font-weight: 300;
line-height:1em;
text-align: center;
color: #4DC3FA;
}

h2 {
font-size:1em; 
font-weight: 300;
text-align: center;
display: block;
line-height:1em;
padding-bottom: 2em;
color: #FB667A;
}

h2 a {
font-weight: 700;
text-transform: uppercase;
color: #FB667A;
text-decoration: none;
}

.blue { color: #185875; }
.yellow { color: #FFF842; }

.connected { 
    font-weight: 400;
    font-size: 0.65em;
    text-align: center; 
    vertical-align: middle;
    color: #88CC88; }
.not_connected { font-weight: 400;
    font-size: 0.65em;
    text-align: center; 
    vertical-align: middle;
    color: #EE8888; }

.container th h1 {
    font-weight: bold;
    font-size: 1em;
    text-align: left;
    color: #185875;
}

.container th > td {
color: #cccccc;
}

.container td {
    /* font-weight: bold; */
    font-weight: 400;
    font-size: 0.65em;
    color: #44ff44;
    text-align: center; 
    vertical-align: middle;
}

.toggle {
    font-weight: 400;
    font-size: 0.65em;
    color: #666666;
    text-align: center; 
    vertical-align: middle;
}

.active {
    color: #DDDDDD;
}


.fixed-width td {
    width: 100px;
}

.right {
    text-align: right !important;
}

.container {
    text-align: center;
    overflow: hidden;
    width: 95%;
    margin: 0 auto;
    display: table;
    padding: 0 0 0 0;
}

.container td, .container th {
    padding-bottom: 0.2%;
    padding-top: 0.2%;
    padding-left:0.2%; 
}

/* Background-color of the odd rows */
.container tr:nth-child(odd) {
    background-color: #323C50;
}

/* Background-color of the even rows */
.container tr:nth-child(even) {
    background-color: #2C3446;
}

.container th {
    background-color: #1F2739;
}

.container td:first-child { color: #FB667A; }

.spread {
    font-weight: bold;
    font-size: 0.65em;
    }

.symbol { color: #FB667A; }
.text { color: #999999; }
.value { color: #44ff44; }

.titulo {
    font-weight: bold;
    font-size: 1em;
    color: #999999;
    }

.container tr:hover {
background-color: #464A52;
-webkit-box-shadow: 0 6px 6px -6px #0E1119;
    -moz-box-shadow: 0 6px 6px -6px #0E1119;
        box-shadow: 0 6px 6px -6px #0E1119;
}

.MuiFormLabel-root {
    background-color: #433e59;
    color: #bbb; 
}

.MuiBox-root {
    background-color: #433e59;
    color: #bbb; 
}


.MuiInputBase-root {
    background-color: #433e59;
    color: #bbb; 
}

.MuiOutlinedInput-notchedOutline {
    border-color: #bbb;
}

.MuiSvgIcon-root {
    color: #bbb; 
}

.hidden-title {
    display: none;
}


.container td:hover {
}

@media (max-width: 800px) {
/*.container td:nth-child(4),
 .container th:nth-child(4) { display: none; } */
}
`

export const UNKNOWN = '#555555'
export const UTC_ALL = '#995555'
export const UTC_0_8_16 = '#559955'
export const UTC_4_12_20 = '#AAAA44'

export const exchanges = [
    { id: 1, text: 'BINANCE', active:true, funding_hours: UTC_0_8_16, fixed_funding: false },
    { id: 2, text: 'DERIBIT', active:true, funding_hours: UNKNOWN, fixed_funding: false },
    { id: 3, text: 'HTX', active:true, funding_hours: UTC_0_8_16, fixed_funding: true },
    { id: 4, text: 'BITMEX', active:true, funding_hours: UTC_4_12_20, fixed_funding: true },
    { id: 5, text: 'BYBIT', active:true, funding_hours: UTC_0_8_16, fixed_funding: false },
    { id: 6, text: 'KUCOIN', active:true, funding_hours: UTC_4_12_20, fixed_funding: true },
    { id: 7, text: 'OKX', active:true, funding_hours: UTC_0_8_16, fixed_funding: true },
    { id: 8, text: 'POLONIEX', active:true, funding_hours: UTC_4_12_20, fixed_funding: true },
    { id: 9, text: 'PHEMEX', active:true, funding_hours: UTC_0_8_16, fixed_funding: false },
    { id: 10, text: 'COINEX', active:true, funding_hours: UTC_0_8_16, fixed_funding: true },
    { id: 11, text: 'KRAKEN', active:true, funding_hours: UTC_ALL, fixed_funding: false },
    //{ id: 12, text: 'DELTA', active:true, funding_hours: UNKNOWN, fixed_funding: true },
    { id: 13, text: 'BINGX', active:true, funding_hours: UTC_0_8_16, fixed_funding: false },
    //{ id: 14, text: 'GATE.IO', active:true, funding_hours: UTC_0_8_16, fixed_funding: false },
    { id: 15, text: 'MEXC', active:true, funding_hours: UTC_0_8_16, fixed_funding: false },
    { id: 16, text: 'DYDX', active:true, funding_hours: UTC_0_8_16, fixed_funding: false },
    { id: 17, text: 'HYPE', active:true, funding_hours: UTC_0_8_16, fixed_funding: false }
]
 
export const ConfigProvider = ({ children }) => {

    const [config, setConfig] = useLocalStorage('config', default_config)
    //const [subscriptions,setSubscriptions ] = useLocalStorage('subscriptions',{})
    const [funding_exceptions, set_funding_exceptions] = useState([])
 
    useEffect(() => {
        const abortController = new AbortController()
        // creating an AbortController
        fetch("https://www.fundingarb.com.ar/exceptions", {
            signal: abortController.signal
          })
          // passing the signal to the query
          .then(response => response.json())
          .then(exceptions => 
            {
                let exceptions_exchanges = exceptions.exchanges
                set_funding_exceptions(exceptions_exchanges)
            })
          .catch(error => {
            if (error.name === 'AbortError') return
            // if the query has been aborted, do nothing
            throw error
          })
      
        return () => {
          abortController.abort()
          // stop the query by aborting on the AbortController on unmount
        }
      }, [])


    const getAccount = (source) => {
        if (Object.prototype.hasOwnProperty.call(config,'accounts')) {
            let account = config.accounts.find( account => account.exchange === source )
            return account
        }
        return undefined
    }



    const initialData = []
   
    let equivalences = [
        { source: BITMEX, replace: 'BTC', with: 'XBT'},
        { source: KUCOIN, replace: 'BTC', with: 'XBT'},
        { source: KRAKEN, replace: 'BTC', with: 'XBT'}
    ]
    let symbol_list = []

    config.symbols.forEach(symbol => {
        symbol_list.push({source: FTX, source_symbol: symbol, symbol: `${symbol.symbol}-PERP`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: BYBIT, source_symbol: symbol, symbol: `${symbol.symbol}USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: BITMEX, source_symbol: symbol, symbol: `${symbol.symbol}USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: BITMEX, source_symbol: symbol, symbol: `${symbol.symbol}EUR`.toUpperCase(), type: EUR, contract: PERPETUAL})
        symbol_list.push({source: OKX, source_symbol: symbol, symbol: `${symbol.symbol}-USD-SWAP`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: HTX, source_symbol: symbol, symbol: `${symbol.symbol}-usd`.toLowerCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: HTX, source_symbol: symbol, symbol: `${symbol.symbol}-usdt`.toLowerCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: BINANCE, source_symbol: symbol, symbol: `${symbol.symbol}usd_perp`.toLowerCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: BINANCE, source_symbol: symbol, symbol: `${symbol.symbol}usdt`.toLowerCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: BINANCE, source_symbol: symbol, symbol: `${symbol.symbol}busd`.toLowerCase(), type: BUSD, contract: PERPETUAL})
        symbol_list.push({source: BYBIT, source_symbol: symbol, symbol: `${symbol.symbol}USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: BITMEX, source_symbol: symbol, symbol: `${symbol.symbol}USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: OKX, source_symbol: symbol, symbol: `${symbol.symbol}-USDT-SWAP`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: KUCOIN, source_symbol: symbol, symbol: `${symbol.symbol}USDM`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: KUCOIN, source_symbol: symbol, symbol: `${symbol.symbol}USDTM`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: DERIBIT, source_symbol: symbol, symbol: `${symbol.symbol}-PERPETUAL`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: DERIBIT, source_symbol: symbol, symbol: `${symbol.symbol}-USDT-PERPETUAL`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: POLONIEX, source_symbol: symbol, symbol: `${symbol.symbol}USDTPERP`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: ASCENDEX, source_symbol: symbol, symbol: `${symbol.symbol}-PERP`.toUpperCase(), type: USDT, contract: PERPETUAL})
        //symbol_list.push({source: DELTA_EXCHANGE, source_symbol: symbol, symbol: `${symbol.symbol}USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        //symbol_list.push({source: DELTA_EXCHANGE, source_symbol: symbol, symbol: `${symbol.symbol}USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: GATE_IO, source_symbol: symbol, symbol: `${symbol.symbol}_USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: GATE_IO, source_symbol: symbol, symbol: `${symbol.symbol}_USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: BITGET, source_symbol: symbol, symbol: `${symbol.symbol}usd`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: BITGET, source_symbol: symbol, symbol: `${symbol.symbol}USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: COINEX, source_symbol: symbol, symbol: `${symbol.symbol}USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: COINEX, source_symbol: symbol, symbol: `${symbol.symbol}USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})
        symbol_list.push({source: KRAKEN, source_symbol: symbol, symbol: `PI_${symbol.symbol}USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: KRAKEN, source_symbol: symbol, symbol: `PF_${symbol.symbol}USD`.toUpperCase(), type: USDT, contract: PERPETUAL})

        symbol_list.push({source: PHEMEX, source_symbol: symbol, symbol: `${symbol.symbol}USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: PHEMEX, source_symbol: symbol, symbol: `${symbol.symbol}USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})
    
        symbol_list.push({source: BINGX, source_symbol: symbol, symbol: `${symbol.symbol}-USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
        symbol_list.push({source: BINGX, source_symbol: symbol, symbol: `${symbol.symbol}-USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})

        symbol_list.push({source: MEXC, source_symbol: symbol, symbol: `${symbol.symbol}_USDT`.toUpperCase(), type: USDT, contract: PERPETUAL})

        symbol_list.push({source: DYDX, source_symbol: symbol, symbol: `${symbol.symbol}-USD`.toUpperCase(), type: COIN, contract: PERPETUAL})

        symbol_list.push({source: HYPE, source_symbol: symbol, symbol: `${symbol.symbol}-USD`.toUpperCase(), type: COIN, contract: PERPETUAL})
    })

    // Corregir los symbols de Phemex
    
    //symbol_list.splice(symbol_list.findIndex(symbol => symbol.source===PHEMEX && symbol.symbol === 'BTCUSD'), 1)
    //symbol_list.push({source: PHEMEX, source_symbol: config.symbols.find(symbol => symbol.symbol === 'ETH'), symbol: 'cETHUSD'.toUpperCase(), type: COIN, contract: PERPETUAL})
    //symbol_list.push({source: PHEMEX, source_symbol: config.symbols.find(symbol => symbol.symbol === 'BTC'), symbol: 'uBTCUSD'.toUpperCase(), type: USDT, contract: PERPETUAL})
    //symbol_list.push({source: PHEMEX, source_symbol: config.symbols.find(symbol => symbol.symbol === 'BTC'), symbol: 'BTCUSD'.toUpperCase(), type: COIN, contract: PERPETUAL})

    // Eliminar SOLUSD de ByBit
    //symbol_list.splice(symbol_list.findIndex(symbol => symbol.source===BYBIT && symbol.symbol === 'SOLUSD'), 1)

    
    equivalences.forEach(equivalence => {
        symbol_list.forEach(symbol => {
            if (symbol.source === equivalence.source) {
                symbol_list[symbol_list.indexOf(symbol)].symbol = 
                symbol.symbol.replaceAll(equivalence.replace,equivalence.with)
            }
        })
    })

    futures_json.forEach (future => {
        future.symbols.forEach(symbol => {
            let source_symbol = tools.find_source_symbol(symbol.source_symbol,config.symbols)
            if (source_symbol !== undefined) {
            let expiry = undefined
            if (symbol.expiry!=undefined) expiry = new Date(symbol.expiry)
            symbol_list.push({source: tools.find_source(future.source), 
                              source_symbol: source_symbol, 
                              symbol: symbol.symbol, 
                              type: tools.find_type(symbol.type), 
                              contract: FUTURES,
                              expiry: expiry})
            }
        })
    })
    
    tools.addIdToSymbols(symbol_list)
    tools.setRelatedSymbols(symbol_list)
    tools.set_multiplier(symbol_list)

    symbol_list.forEach (symbol => {
        let type_icon = <BiDollarCircle />
        if (symbol.type===USDT) type_icon=<Icon icon={tetherIcon} />
        if (symbol.type===BUSD) type_icon=<Icon icon={binanceIcon} />
        
            initialData.push({
                id: symbol.id,
                source: symbol.source.SOURCE,
                source_symbol: symbol.source_symbol.symbol,
                symbol: symbol.symbol.toUpperCase(),
                last: '',
                type: symbol.type,
                type_icon: type_icon,
                funding_rate: '', 
                predicted_funding_rate: '',
                modified: '',
                refresh: '',
                status: <MdHourglassEmpty />,
                contract: symbol.contract,
                expiry: symbol.expiry,
                related_symbol_id: symbol.related_symbol_id,
                listed: true
            })
        
    })

    //console.log('Change in Configuration',symbol_list)

    return (
        <ConfigContext.Provider value={{
            config, setConfig, symbol_list, initialData, Styles, getAccount, funding_exceptions }}>
            { children }
        </ConfigContext.Provider>
    )

}
