import axios from 'axios'
import * as tools from '../tools/tools'
import { useRef, useEffect, useCallback } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import { COIN,USDT } from '../context/ConfigProvider'
import ConsoleHelper from '../tools/ConsoleHelper'
import limit from '../tools/limit';

export const SOURCE = 'BITGET'

let listed_symbols = []

/*
API Information
COIN
https://bitgetlimited.github.io/apidoc/en/swap/#websocketapi
USDT
https://bitgetlimited.github.io/apidoc/en/mix/#websocketapi

*/

export function Bitget(ws_url,symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
 
    const bitget_funding = useCallback((symbols,update_internal) => {
        symbols.forEach (symbol => {
            if (symbol.type===USDT)
            fetch_bitget(symbols, symbol.symbol,update_internal)
        })
        async function fetch_bitget(symbols,symbol,update_internal){
                const res = await axios('https://www.fundingarb.com.ar/bitget_futures', 
                { params: { symbol: symbol } })
        
                if ( res?.data?.data?.fundingRate)
                {
                    update_internal(symbols,symbol,{
                        funding_rate: parse100(parseFloat(res.data.data.fundingRate)),
                    })
                }
        }
    }, [parse100])


    useEffect(() => {

        bitget_funding(symbols,update_internal)
        const interval = setInterval(() => {
            bitget_funding(symbols,update_internal)
        }, 20000)

        const connect = () => {
            let ws = new ZSocket(ws_url, NO_COMPRESSION); webSocket.current = ws
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    if (symbol.type===COIN)
                    {
                    ws.sendJson({'op': 'subscribe', 'args': ['swap/funding_rate:'+symbol.symbol.toLowerCase()]})
                    ws.sendJson({'op': 'subscribe', 'args': ['swap/ticker:'+symbol.symbol.toLowerCase()]})
                    } else if (symbol.type===USDT)
                    {
                        let json_message = {
                            'op':'subscribe',
                            'args':[
                                {
                                    'instType':'mc',
                                    'channel':'ticker',
                                    'instId': symbol.symbol.toLowerCase()
                                }
                            ]
                        }
                        ws.sendJson(json_message)
                    }
                })
                ping_interval.current = setInterval(() => {
                    ws.sendMessage('ping')
                }, 20000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (jsonMessage) => {
                if (jsonMessage?.data?.[0])
                {
                    let symbol_data = jsonMessage.data[0]
                    let json_symbol
                    if (symbol_data.instrument_id) json_symbol = symbol_data.instrument_id.toUpperCase()
                    if (symbol_data.instId) json_symbol = symbol_data.instId.toUpperCase()

                    if (symbol_data.last)
                    {    
                        if (json_symbol)
                            update_internal(symbols,json_symbol,{last: parse(symbol_data.last)})
                    }

                    if (symbol_data.funding_rate)
                    {
                        if (json_symbol)
                            update_internal(symbols,json_symbol,{funding_rate: parse100(symbol_data.funding_rate)})
                    }

                }
                else if (jsonMessage?.action === 'ping') { /* Ping */ }
                else if (jsonMessage?.event === 'subscribe') { /* Subscription made */ }
                else if (jsonMessage?.errorCode === 30010) { /* Symbol not exists */ }
                else if (jsonMessage?.code === 30001) { /* Symbol not exists */ }
                else
                {
                    ConsoleHelper(SOURCE,jsonMessage)
                }
            }
        }
        connect()

        return () => {
            //ws.close()
            //clearInterval(interval)
        }

    }, [ws_url,symbols,update_internal,parse,parse100,bitget_funding])

    return webSocket.current
}

