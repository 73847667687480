import React, { useContext } from 'react'
import 'fontsource-roboto'
import './App.css'
import { ConfigContext } from './context/ConfigProvider'
import PositionsTable from './tables/PositionsTable'

function Positions() {
  const  { Styles,config } = useContext(ConfigContext)


  return (
    <Styles>
      <br></br><br></br><br></br><br></br>
      <PositionsTable title='Positions' positions={config.positions} />
      <br></br>
    </Styles>
  )
  
}
export default Positions

