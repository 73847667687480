import { TextField } from '@mui/material';
import { useState } from 'react'

export const NumberField = ({title, number, setNumber, step}) => {

  const [selected, setSelected] = useState(false)

  const onBlurField = () => {
    setSelected(false)
  }

  const changeNumber = (value) => {
    setNumber(value)
  }

  const field = () => {
    return (
      <TextField
          id="standard-number"
          label={title}
          type="number"
          inputProps={{
            step: step
          }}
          onChange={(event) => changeNumber(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => onBlurField()}
          variant="standard"
          defaultValue={number}
        />
    )
  }

  const text = () => {
    return (
      <>
        <span
        className={'toggle'}
        onClick={() => setSelected(true)}>{title}:&nbsp;
        </span>
        <span
        className={'toggle active'}
        onClick={() => setSelected(true)}>{number}
        </span>
      </>
    )
  }

  return (
    <div>
      { selected ? field() : text() }
    </div>
  )

}

