import React from 'react'
import { useTable } from 'react-table'

export function Table({ columns, data }) {

   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     rows, 
     prepareRow,
   } = useTable({ columns, data, initialState: { hiddenColumns: ['id'] } })
 
   return (
      <table className="container" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <td {...column.getHeaderProps()}>
                  {column.render('Header')}
                </td>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}> 
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        </table>
  )
 }
