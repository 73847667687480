import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'
 
export const SOURCE = 'ASCENDEX'

/*
API Information
https://ascendex.github.io/ascendex-futures-pro-api-v2/#introducing-futures-pro-v2-apis
*/

export function Ascendex(symbols,update_internal,update_connection){

    const z_socket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {
        const connect = () => {
            let ws = new ZSocket('wss://ascendex.com:443/api/pro/v2/stream', NO_COMPRESSION); z_socket.current = ws
 
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
    
                ws.sendJson({'op':'sub', 'id':1234, 'ch':'futures-pricing-data'})
                 
                ping_interval.current = setInterval(() => {
                    ws.sendJson({ 'op': 'ping' })
                }, 20000)
            }
    
            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }
    
            ws.jsonReceived = (jsonMessage) => {
                if (Object.prototype.hasOwnProperty.call(jsonMessage,'con')) // contracts
                {
                    jsonMessage.con.forEach(symbol_data => {
                        symbols.forEach (symbol => {
                            if (symbol_data.s===symbol.symbol)
                            {
                                update_internal(symbols,symbol_data.s,{
                                    last: parse(symbol_data.mp),
                                    funding_rate: parse100(symbol_data.r)
                                })
                            }
                        })
    
                    })
                }
                else if (jsonMessage?.m === 'connected') { /* Connection Established */ }
                else if (jsonMessage?.m === 'sub') { /* Subscription made */ }
                else if (jsonMessage?.m === 'ping') { /* Ping */ }
                else if (jsonMessage?.m === 'pong') { /* Ping */ }
                else
                {
                    ConsoleHelper(SOURCE,jsonMessage)
                }
            }
        }
        connect()

        return () => {
        }

    }, [symbols,update_internal,parse,parse100])

    return z_socket.current
}

