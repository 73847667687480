import React, { useContext } from 'react'
import * as tools from '../tools/tools'
import {Table} from './Table'
import { FundingContext } from '../context/FundingDataProvider'
import EventEmitter from '../tools/Events'
import { MdLock,MdElectricBolt,MdOpenInNew } from 'react-icons/md'
import {exchanges,UTC_ALL,UTC_0_8_16,UTC_4_12_20, ConfigContext} from '../context/ConfigProvider'
import { is_active } from '../components/toggle'

import { Link } from 'react-router-dom';

export default function SuperOpportunitiesTable({title, types, investment, filteredSymbols, filteredExchanges, funding_exceptions, filtered_columns, filters, enable_filters, group_by_symbols, sort_by})
{ 
    const { fundingData } = useContext(FundingContext)
    const [openedModal, setOpenedModal] = React.useState(false)
    const handleOpen = () => setOpenedModal(true)
    const handleClose = () => setOpenedModal(false)

    const long_color = '#88CC88' 
    const short_color = '#EE8888'
    const symbol_color = '#CCCCCC'
    const missing_color = '#666666'
    const investment_color = '#AAAAAA'
    const GMT = -3
    const date = new Date()
    let current_hour = date.getUTCHours()
    let next_funding_hours = tools.get_next_four_funding_hours(current_hour)
    let funding_hours = ['h','h','h','h']
    if (next_funding_hours!=undefined) 
      if (next_funding_hours.next_hours!=undefined) 
      {
        funding_hours = [tools.adjustGMT(next_funding_hours.next_hours[0],GMT)+'h',
        tools.adjustGMT(next_funding_hours.next_hours[1],GMT)+'h',
        tools.adjustGMT(next_funding_hours.next_hours[2],GMT)+'h',
        tools.adjustGMT(next_funding_hours.next_hours[3],GMT)+'h']
      }

    const columns = []
    columns.push ({
      Header: 'ID',
      accessor: 'id',
    })
    columns.push({
      Header: 'Symbol',
      accessor: 'symbol',
      Cell: ({ value }) => {
        // onClick={() => {EventEmitter.emit('highlightRow',row.values)}}
        return <div style={{ color: symbol_color }}
                    onClick={() => {EventEmitter.emit('highlightRow',row.values)}} >&nbsp;{value}</div>
      }
    })
    columns.push({
      Header: '',
      accessor: 'link',
      Cell: ({row}) => {
        let link = "/strategy/"+row.original.symbol+"/"+row.original.long_exchange+"/"+row.original.short_exchange
        return <div><Link to={link}><MdOpenInNew /></Link></div>
      }
    })

    if (is_active('EXCHANGES',filtered_columns))
      columns.push({
        Header: 'E',
        accessor: 'exchanges',
        Cell: ({ value }) => {
          return <div style={{ color: symbol_color }}>&nbsp;{value}</div>
        }
      })
    columns.push({
      Header: 'Long',
      accessor: 'long_exchange',
      Cell: ({ value }) => {
        return <div style={{ color: long_color }}>&nbsp;{value}</div>
      }
    })
    columns.push(          {
      Header: 'H',
      accessor: 'long_exchange_hours',
      Cell: ({ row,value }) => {
        let exchange = exchanges.filter(exchange => exchange.text == row.original.long_exchange)
        return (
          <div style={{ color: exchange[0].funding_hours }}>{exchange[0].fixed_funding ? <MdLock /> : <MdElectricBolt />}</div>
          )
      }
    })
    columns.push(          {
      Header: 'Last',
      accessor: 'long_last',
      Cell: ({ row,value }) => {
        return <div style={{ color: long_color }}>&nbsp;{value}</div>
      }
    })    

    if (is_active('FUNDING',filtered_columns))
    columns.push(          {
      Header: 'Funding',
      accessor: 'long_funding_rate',
      Cell: ({ row,value }) => {
        return <div style={{ color: long_color }}>&nbsp;{value}</div>
      }
    })

    if (is_active('PREDICTED',filtered_columns))
    columns.push(          {
      Header: 'Predicted',
      accessor: 'long_predicted_funding_rate',
      Cell: ({ row,value }) => {
        if (row.original.long_missing_predicted) return <div style={{ color: missing_color }}>&nbsp;{value}</div>
        else return <div style={{ color: long_color }}>&nbsp;{value}</div>
      }
    })

    columns.push(          {
      Header: 'Short',
      accessor: 'short_exchange',
      Cell: ({ row,value }) => {
        return <div style={{ color: short_color }}>&nbsp;{value}</div>
      }
    })
    columns.push(          {
      Header: 'H',
      accessor: 'short_exchange_hours',
      Cell: ({ row,value }) => {
        let exchange = exchanges.filter(exchange => exchange.text == row.original.short_exchange)
        return (
        <div style={{ color: exchange[0].funding_hours }}>{exchange[0].fixed_funding ? <MdLock /> : <MdElectricBolt />}</div>
        )
      }
    })
    columns.push(          {
      Header: 'Last',
      accessor: 'short_last',
      Cell: ({ row,value }) => {
        return <div style={{ color: short_color }}>&nbsp;{value}</div>
      }
    })

    if (is_active('FUNDING',filtered_columns))
    columns.push(          {
      Header: 'Funding',
      accessor: 'short_funding_rate',
      Cell: ({ row,value }) => {
        return <div style={{ color: short_color }}>&nbsp;{value}</div>
      }
    })
    
    if (is_active('PREDICTED',filtered_columns))
    columns.push(          {
      Header: 'Predicted',
      accessor: 'short_predicted_funding_rate',
      Cell: ({ row,value }) => {
        if (row.original.short_missing_predicted) return <div style={{ color: missing_color }}>&nbsp;{value}</div>
        else return <div style={{ color: short_color }}>&nbsp;{value}</div>
      }
    })

  columns.push(          {
    Header: 'Entry %',
    accessor: 'entry_arb_percent',
    Cell: ({ value }) => {
        return tools.format_positive_funding(value)
    }
  })

  if (is_active('NEXT HOURS',filtered_columns))
  columns.push(
  {
    Header: funding_hours[0],
    accessor: 'first_funding_settlement',
    Cell: ({ value }) => {
        return tools.format_positive_funding(value)
    }
  })

  if (is_active('NEXT HOURS',filtered_columns))
  columns.push({
    Header: funding_hours[1],
    accessor: 'second_funding_settlement',
    Cell: ({ value }) => {
        return tools.format_positive_funding(value)
    }
  })

  if (is_active('NEXT HOURS',filtered_columns))
  columns.push({
    Header: funding_hours[2],
    accessor: 'third_funding_settlement',
    Cell: ({ row,value }) => {
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
      else 
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('NEXT HOURS',filtered_columns))
  columns.push({
    Header: funding_hours[3],
    accessor: 'fourth_funding_settlement',
    Cell: ({ row,value }) => {
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
      else 
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'A '+funding_hours[0],
    accessor: 'first_sum_funding_settlement',
    Cell: ({ value }) => {
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'A '+funding_hours[1],
    accessor: 'second_sum_funding_settlement',
    Cell: ({ value }) => {
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'A '+funding_hours[2],
    accessor: 'third_sum_funding_settlement',
    Cell: ({ row,value }) => {
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
      else 
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'A '+funding_hours[3],
    accessor: 'fourth_sum_funding_settlement',
    Cell: ({ row,value }) => {
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
      else 
      return tools.format_positive_funding(value)
    }
  })


  if (is_active('ENTRY + ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'E+A '+funding_hours[0],
    accessor: 'first_funding_settlement_plus_entry_arb_percent',
    Cell: ({ value }) => {
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('ENTRY + ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'E+A '+funding_hours[1],
    accessor: 'second_sum_funding_settlement_plus_entry_arb_percent',
    Cell: ({ value }) => {
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('ENTRY + ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'E+A '+funding_hours[2],
    accessor: 'third_sum_funding_settlement_plus_entry_arb_percent',
    Cell: ({ row,value }) => {
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
      else 
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('ENTRY + ACCUMULATED',filtered_columns)) 
  columns.push({
    Header: 'E+A '+funding_hours[3],
    accessor: 'fourth_sum_funding_settlement_plus_entry_arb_percent',
    Cell: ({ row,value }) => {
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_positive_funding(value,'',true)
      else 
      return tools.format_positive_funding(value)
    }
  })

  if (is_active('QUANTITY',filtered_columns)) 
  columns.push({
    Header: 'Quantity',
    accessor: 'quantity',
    Cell: ({ value }) => {
      return <div style={{ color: investment_color }}>&nbsp;{value}</div>
    }
  })

  if (is_active('ENTRY $',filtered_columns)) 
  columns.push({
    Header: 'Entry $',
    accessor: 'entry_cost',
    Cell: ({ value }) => {
      if (value=='NaN') return ''
      else return tools.format_currency(value) 
    }
  })

  if (is_active('PNL $',filtered_columns)) 
  columns.push({
    Header: 'PNL'+funding_hours[0],
    accessor: 'first_pnl',
    Cell: ({ value }) => {
      if (value=='NaN') return ''
      else return tools.format_currency(value) 
    }
  })
  if (is_active('PNL $',filtered_columns)) 
  columns.push({
    Header: 'PNL'+funding_hours[1],
    accessor: 'second_pnl',
    Cell: ({ value }) => {
      if (value=='NaN') return ''
      else return tools.format_currency(value) 
    }
  })
  if (is_active('PNL $',filtered_columns)) 
  columns.push({
    Header: 'PNL'+funding_hours[2],
    accessor: 'third_pnl',
    Cell: ({ row,value }) => {
      if (value=='NaN') return ''
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_currency(value,'',true) 
      else 
      return tools.format_currency(value) 
    }
  })
  if (is_active('PNL $',filtered_columns)) 
  columns.push({
    Header: 'PNL'+funding_hours[3],
    accessor: 'fourth_pnl',
    Cell: ({ row,value }) => {
      if (value=='NaN') return ''
      if (row.original.long_missing_predicted || row.original.short_missing_predicted) return tools.format_currency(value,'',true) 
      else 
      return tools.format_currency(value) 
    }
  })

    let table_data = tools.data_super_opportunities(fundingData,types,funding_exceptions,date,next_funding_hours,filters,enable_filters)
    let table_with_investment = tools.fill_investment_data(table_data,investment)
    let sanitized_table = tools.sanitize(table_with_investment)
    let fees = ''
    if (table_data.length>0)
    fees = table_data[0].fees

    let filtered_data = sanitized_table.filter((e) => {
      return e.exchanges>0
    })


    let filtered_symbols_data = []
    filteredSymbols.forEach(symbol => {
        if (symbol.active)
        {
          filtered_data.forEach(row => {
            if (row['symbol']==symbol.text) filtered_symbols_data.push(row)
        })
        }
    })

    let filtered_exchanges_data = []
        filtered_symbols_data.forEach(row => {
              let add = true
              filteredExchanges.forEach(exchange => {
                if ((row['long_exchange']==exchange.text) || (row['short_exchange']==exchange.text)) 
                {
                     if (!exchange.active) {
                      add=false
                      return
                    }
                }
              })
              if (add) filtered_exchanges_data.push(row)
        })

    if (sort_by==0)
    {
      filtered_exchanges_data = filtered_exchanges_data
      .sort(
        (a, b) => {
          return b.entry_arb_percent - a.entry_arb_percent }
      )
    }
    else
    if (sort_by==1)
    {
      filtered_exchanges_data = filtered_exchanges_data
      .sort(
        (a, b) => {
          return b.first_sum_funding_settlement - a.first_sum_funding_settlement }
      )
    }
    else
    if (sort_by==2)
    {
      filtered_exchanges_data = filtered_exchanges_data
      .sort(
        (a, b) => {
          return b.second_sum_funding_settlement - a.second_sum_funding_settlement }
      )
    }

    
    filtered_exchanges_data.length = filters.limit

    if (group_by_symbols[0].active){
      filtered_exchanges_data = tools.sort_by_symbol(filtered_exchanges_data)
    }


    return (
        <>
            <center><span className='spread text'>{title ? title : symbol} - Exchange Fee:  {fees ? '$ '+fees : ''}</span><span className='spread text'> - (UTC {date.getUTCHours()+":"+date.getUTCMinutes()})</span></center>
            <Table columns={columns} data={filtered_exchanges_data} />
        </>
    )

}



//FundingTable.whyDidYouRender = true;