import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { PERPETUAL } from '../context/ConfigProvider'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import ConsoleHelper from '../tools/ConsoleHelper'
export const SOURCE = 'KRAKEN'

/*
API Information
https://support.kraken.com/hc/es/sections/360012894412-API-Futures
https://github.com/bmoscon/cryptofeed/blob/master/cryptofeed/exchanges/kraken_futures.py

wss://futures.kraken.com/ws/v1
{
    "event": "subscribe",
    "feed": "ticker",
    "product_ids": ["PI_XBTUSD", "PI_ETHUSD"]
}
*/

export function Kraken(symbols,update_internal,update_connection){

    const webSocket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse_e7 = tools.dynamic_decimals_e7
    
    useEffect(() => {
        const connect = () => {
            let ws = new ZSocket('wss://futures.kraken.com/ws/v1', NO_COMPRESSION); webSocket.current = ws
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)

                symbols.forEach (symbol => {
                    switch (symbol.contract)
                    {
                        case PERPETUAL:
                            ws.sendJson(
                                {   event: 'subscribe', 
                                    feed: 'ticker', 
                                    product_ids: [symbol.symbol] 
                                }
                            )
                        break
                        default:
                    }
                })

                ping_interval.current = setInterval(() => {
                    ws.send('ping')
                }, 20000)
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            ws.jsonReceived = (jsonMessage) => {
                if (Object.prototype.hasOwnProperty.call(jsonMessage,'product_id'))
                {
                    update_internal(symbols,jsonMessage.product_id,{
                        last: parse(jsonMessage.last),
                        funding_rate: parse_e7(jsonMessage.relative_funding_rate),
                        predicted_funding_rate: parse_e7(jsonMessage.relative_funding_rate_prediction)
                    })
                }
                else {
                    //ConsoleHelper({SOURCE,jsonMessage})
                }
            }
        }
        connect()

        return () => {
            //ws.close()
        }

    }, [symbols,update_internal,parse,parse_e7])

    return webSocket.current
}

