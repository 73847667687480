import * as tools from '../tools/tools'
import { useRef, useEffect } from 'react'
import { ZSocket, NO_COMPRESSION } from './ZSocket'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'
 
export const SOURCE = 'DYDX'


export function dYdX(symbols,update_internal,update_connection){

    const z_socket = useRef(null)
    const ping_interval = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    
    useEffect(() => {
        const connect = () => {
            let ws = new ZSocket('wss://indexer.dydx.trade/v4/ws', NO_COMPRESSION); z_socket.current = ws
 
            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
    
                ws.sendJson({type:"subscribe",channel:"v4_markets",batched:true})

                symbols.forEach (symbol => {
                    ws.sendJson({type:"subscribe",channel:"v4_trades",id:symbol.symbol,batched:true})
                })

                ping_interval.current = setInterval(() => {
                    ws.sendJson({type:"ping"})
                }, 20000)
            }
    
            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }
    
            ws.jsonReceived = (jsonMessage) => {
                if (jsonMessage?.channel === "v4_markets" && jsonMessage?.type === "subscribed")
                {
                    const { contents } = jsonMessage;
                    const { markets } = contents;

                    symbols.forEach((symbol) => {
                        if (markets[symbol.symbol]) {
                            update_internal(symbols,symbol.symbol,{
                                funding_rate: parse100(markets[symbol.symbol].nextFundingRate*8)
                            })
                        } else {
                        }
                    })
                }
                if (jsonMessage?.channel === "v4_markets" && jsonMessage?.type === "channel_batch_data")
                {
                    if (jsonMessage.contents!=undefined)
                    {
                        jsonMessage?.contents?.forEach((content) => {

                            if (content['trading'])
                            {
                                let trading = content['trading']
                                symbols.forEach((symbol) => {
                                    if (trading[symbol.symbol]) {
                                        let symbol_data = trading[symbol.symbol]
                                        if (symbol_data.nextFundingRate)
                                        {
                                            update_internal(symbols,symbol.symbol,{
                                                funding_rate: parse100(trading[symbol.symbol].nextFundingRate*8)
                                            })
                                        }
                                    }
                                })
                            }

                        })
                    }
                }
                if (jsonMessage?.channel === "v4_trades" && jsonMessage?.type === "subscribed")
                {
                    if (jsonMessage.contents!=undefined)
                    {
                        let contents = jsonMessage.contents
                        if (contents['trades'])
                        {
                            let last_trade = contents['trades'][0]
                            if (last_trade)
                            {
                                update_internal(symbols,jsonMessage.id,{
                                    last: parse(last_trade.price)
                                })
                            }
                        }
                    }
                }
                else if (jsonMessage?.channel === "v4_trades" && jsonMessage?.type === "channel_batch_data")
                {
                    if (jsonMessage.contents!=undefined)
                    {
                        try{
                            jsonMessage?.contents?.forEach((content) => {
                                let last_trade = content['trades'][0]
                                if (last_trade)
                                {
                                    update_internal(symbols,jsonMessage.id,{
                                        last: parse(last_trade.price)
                                    })
                                }
                            })
                        }
                        catch ( Exception ) {
                            console.log(Exception)
                        }
                    }
                }

            }
        }
        connect()

        return () => {
        }

    }, [symbols,update_internal,parse,parse100])

    return z_socket.current
}

