import * as tools from '../tools/tools'
import { useRef, useEffect, useContext } from 'react'
import { ConfigContext } from '../context/ConfigProvider'
import CryptoJS from 'crypto-js'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import ConsoleHelper from '../tools/ConsoleHelper'
import { ZSocket, NO_COMPRESSION } from './ZSocket'

export const SOURCE = 'BITMEX'

export function Bitmex(symbols,update_internal,update_connection){

    const ws_url = 'wss://www.bitmex.com/realtime'
    const z_socket = useRef(null)
    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100

    const  { getAccount } = useContext(ConfigContext)

    useEffect(() => {
        let account = getAccount(SOURCE)

        const connect = () => {
            let ws = new ZSocket(ws_url, NO_COMPRESSION); z_socket.current = ws

            z_socket.current.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                symbols.forEach (symbol => {
                    z_socket.current.sendJson({op: 'subscribe', args: [`instrument:${symbol.symbol}`]})
                })
                if (account !== undefined){
                    let {key,expires,signature} = getAuthHeaders({key: account.api_key, secret: account.api_secret})
                    z_socket.current.sendJson({'op': 'authKeyExpires', 'args': [key,expires,signature]})
                    z_socket.current.sendJson({op: 'subscribe', args: 'position'})
                }
            }

            z_socket.current.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }

            z_socket.current.jsonReceived = (json_message) => {
                if (json_message?.data!==undefined) 
                if (json_message.data.length>0)
                {
                    if (json_message.table==='position')
                    {
                        if (Object.prototype.hasOwnProperty.call(json_message.data[0],'homeNotional')){
                            update_internal(symbols,json_message.data[0].symbol,{
                                quantity: parse(json_message.data[0].homeNotional)
                            })
                        }
                        if (Object.prototype.hasOwnProperty.call(json_message.data[0],'liquidationPrice')){
                            update_internal(symbols,json_message.data[0].symbol,{
                                liquidation: parse(json_message.data[0].liquidationPrice)
                            })
                        }
                    }
                    else if (json_message.table==='instrument')
                    {
                        /*
                        if (Object.prototype.hasOwnProperty.call(json_message.data[0],'markPrice')){
                            update_internal(symbols,json_message.data[0].symbol,{
                                last: parse(json_message.data[0].markPrice),
                            })
                        }
                        else
                        */
                        if (Object.prototype.hasOwnProperty.call(json_message.data[0],'lastPrice')){
                            update_internal(symbols,json_message.data[0].symbol,{
                                last: parse(json_message.data[0].lastPrice),
                            })
                        }
                        if (Object.prototype.hasOwnProperty.call(json_message.data[0],'fundingRate')){
                            update_internal(symbols,json_message.data[0].symbol,{
                                funding_rate: parse100(json_message.data[0].fundingRate),
                                predicted_funding_rate: parse100(json_message.data[0].indicativeFundingRate),
                            })
                        }
                        if (Object.prototype.hasOwnProperty.call(json_message.data[0],'expiry')){
                            update_internal(symbols,json_message.data[0].symbol,{
                                expiry: Date.parse(json_message.data[0].expiry),
                            })
                        }
                    }
                    else
                    {
                        ConsoleHelper(SOURCE,json_message)
                    }
                }
            }
        }
        connect()

        return () => {
            //webSocket.current.close()
        }
    }, [symbols,update_internal,getAccount,parse,parse100])

    return z_socket.current
}


const getAuthHeaders = ({key,secret}) => {
    if (!key || !secret) return

    const expiration = 60 * 1000
    const start = +new Date()
    const expires = Math.round((start + expiration) / 1000)

    const signature = CryptoJS.HmacSHA256('GET/realtime' + expires, secret).toString(CryptoJS.enc.Hex)

    return {
      key: key,
      expires: expires,
      signature: signature
    }
}

