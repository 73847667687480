import React, { useContext, useState, useEffect } from 'react'
import 'fontsource-roboto'
import './App.css'
import { ConfigContext, USDT } from './context/ConfigProvider'
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom'
import OpportunitiesTable from './tables/OpportunitiesTable'
import axios from 'axios'
import Button from '@mui/material/Button';
import {Toggle} from './components/toggle';
import {exchanges} from './context/ConfigProvider'

const url_prefix = () => {
  if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === 'development') {
      return ''
  } else {
      return ''
  }
}


function Opportunities() {

  const {config, setConfig, Styles} = useContext(ConfigContext)
  const [investment, setInvestment] = useState('')


  let initialElements = []
  let id = 0
  config.symbols.forEach(symbol => {
      id++
      initialElements.push({
        id: id,
        text: symbol.symbol,
        active: true
      })
  })
  

  const [filteredSymbols, setFilteredSymbols] = useState(initialElements)
  const [filteredExchanges, setfilteredExchanges] = useState(exchanges)

  const handleTextInputChange = event => {
    setInvestment(event.target.value)
  }
  const history = useHistory()

  const handleClick = () => {
    axios.get('https://www.fundingarb.com.ar/hot_symbols')
    .then(res => {
      let symbols = []
      res.data.forEach(symbol => {
          symbols.push({
            symbol: symbol,
            highlight_funding: 0.10,
            highlight_predicted_funding: 0.10
          })
      })
      setConfig({symbols: symbols})
      let prefix = url_prefix()
      history.push(`${prefix}/opportunities`)
      window.location.reload()
    })
  }

  return (
    // <Toggle elements={filteredSymbols} setElements={setFilteredSymbols} />
    <Styles>
      <br></br><br></br>
      <center>
      <TextField id="investment" label="Investment" variant="standard"
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} defaultValue="1000"
      onChange= {handleTextInputChange} />
      </center>
      <br></br>
      <center>
      <Toggle elements={filteredSymbols} setElements={setFilteredSymbols} />
      </center>
      <center>
      <Toggle elements={filteredExchanges} setElements={setfilteredExchanges} />
      </center>
      <br></br>
        <br></br>
        <OpportunitiesTable title={'Opportunities'} investment={investment} types={[USDT]} filteredSymbols={filteredSymbols} filteredExchanges={filteredExchanges} />
        <br></br>
        <center>
        <Button onClick={handleClick}>Load Hot Symbols</Button> 
        </center>
    </Styles>
  )
  
}

export default Opportunities