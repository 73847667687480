import * as tools from '../tools/tools'
import { useEffect, useCallback, useRef } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { MdHourglassEmpty } from 'react-icons/md'
import { ZSocket, INFLATE, INFLATE_RAW } from './ZSocket'
import { MdLens } from 'react-icons/md'
import { COIN,USDT } from '../context/ConfigProvider'
import zlib from "react-zlib-js";
import { Buffer } from 'buffer'

import axios from 'axios'
import ConsoleHelper from '../tools/ConsoleHelper'
import rateLimit from 'axios-rate-limit';

export const SOURCE = 'BINGX'

let bingx_listed_symbols = []

/*
Documentacion SWAP
https://github.com/BingX-API/BingX-swap-api-v2-doc
https://bingx-api.github.io/docs/#/swapV2/socket/market.html#Subscribe%20the%20Latest%20Trade%20Detail
https://bingx-api.github.io/docs/#/swapV2/socket/#Access

*/

export function BingX(symbols,update_internal,update_connection,data){

    const parse = tools.dynamic_decimals
    const parse100 = tools.dynamic_decimals_by_100
    const webSocket = useRef(null)

    const bingx_funding = useCallback((symbols,update_internal) => {
        bingx_listed_symbols.forEach (symbol => {
                fetch_bingx_funding(symbols, symbol,update_internal)
        })

        async function fetch_bingx_funding(symbols,symbol,update_internal){
                //const http = rateLimit(axios.create(), { maxRequests: 2, perMilliseconds: 1000, maxRPS: 2 })
                const res = await axios('https://www.fundingarb.com.ar/bingx_premiumIndex', { params: { symbol: symbol } })

                if (res?.data)
                {
                    let data = res.data
                    if (data.code === 0)
                    {
                        if (data?.data!=undefined)
                        {
                            let lastFundingRate = data.data.lastFundingRate
                            update_internal(symbols,symbol,{
                                funding_rate: parse100(lastFundingRate),
                            })
                        }
                    }
                }
        }
    }, [parse100])

    /*
    const bingx_price = useCallback((symbols,update_internal) => {
        symbols.forEach (symbol => {
            fetch_bingx_price(symbols, symbol.symbol,update_internal)
        })

        async function fetch_bingx_price(symbols,symbol,update_internal){
                const res = await axios('https://fundingarb.com.ar/bingx_price', { params: { symbol: symbol } })

                if (res?.data)
                {
                    let data = res.data
                    if (data.code === 0)
                    {
                        update_internal(symbols,symbol,{
                            last: parse(data.data.price),
                        })
                    }
                }
        }
    }, [parse100])
    */

    useEffect(() => {
        symbols.forEach(symbol => { bingx_listed_symbols.push(symbol.symbol) })
        

        const connect = () => {
            let ws = new WebSocket('wss://open-api-swap.bingx.com/swap-market'); webSocket.current = ws

            ws.onopen = () => {
                update_internal(symbols,undefined,{status: <MdCheckCircle />})
                update_connection(SOURCE,true)
                let id = 0
                symbols.forEach (symbol => {
                    let json_symbol = symbol.symbol.toUpperCase()+"@trade"
                    ws.send(JSON.stringify(
                        {
                            id:"id"+id++,
                            reqType: "sub",
                            dataType: json_symbol
                        }
                    ))
                })
            }

            ws.jsonReceived = (jsonMessage) => {
                ConsoleHelper(SOURCE,jsonMessage)
            }

            ws.messageReceived = (message) => {
                ConsoleHelper(SOURCE,message)
            }

            ws.onerror = (error) => {
                ConsoleHelper(SOURCE,error)
            }

            ws.onmessage = async (message) => {
                let array_buffer = await message.data.arrayBuffer()
                let buf = Buffer.from(array_buffer)
                let decodedMsg = zlib.gunzipSync(buf).toString('utf-8')
                let json_message
                if (decodedMsg === "Ping") {
                ws.send('Pong')
                }
                else if (json_message = JSON.parse(decodedMsg))
                {
                    if (json_message.dataType?.includes("@trade"))
                    {
                        let symbol_data = json_message.data[0]
                        symbols.forEach (symbol => {
                            if (symbol_data.s===symbol.symbol)
                            {
                                update_internal(symbols,symbol_data.s,{
                                    last: parse(symbol_data.p),
                                })
                            }
                        })

                    }

                }
                //receivedMessage = decodedMsg;
            }

            ws.onclose = () => {
                update_internal(symbols,undefined,{status: <MdHourglassEmpty />})
                update_connection(SOURCE,false)
                setTimeout(() => connect(), 2000)
            }
        }
        connect()

        bingx_funding(symbols,update_internal)
        const interval_funding = setInterval(() => {
            bingx_listed_symbols = tools.purge_listed_symbols(data,SOURCE,bingx_listed_symbols)
            bingx_funding(symbols,update_internal)
        }, 60000)
        

        update_internal(symbols,undefined,{ status: <MdLens /> })

    }, [symbols,update_internal,parse,parse100])

    return webSocket.current
}